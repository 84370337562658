import React, { useEffect } from "react";
import MenuBar from "./MenuBar";

function DrawerMenu({ showDrawer, handleDrawer }) {
  return (
    <div className={`drawer-menu ${showDrawer ? "active" : ""}`}>
      <div
        className={`drawer-overlay ${showDrawer ? "active" : ""}`}
        onClick={handleDrawer}
      ></div>
      <MenuBar />
    </div>
  );
}

export default DrawerMenu;
