import React, { useEffect, useContext } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import { HowItWorks } from "../utils/SidebarMenu";
import { UserContext } from "../context/UserContext";

function DisbursementSigningAgreement(props) {
  const { setOpened } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="Disbursement and Signing agreement" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              <Typography variant="body1">
                As per regulations, NBFCs must convey in writing to the borrowers, the amount of loan sanctioned along with the terms and conditions including annualized rate of interest and method of
                application thereof. FaircentPro provides the flexibility to its lenders to issue the sanction and disbursement letters in the format they wish at time of loan approval.
              </Typography>
              <Typography variant="body1">
                FaircentPro facilitates the signing of the loan agreement between the borrower and the lenders through a simple online process. The process is not only legally binding just like a
                physical agreement but is also faster and more efficient ensuring faster flow of funds.
              </Typography>
              <Typography variant="body1">
                Loan disbursement is initiated only after the loan agreement has been signed, e/Nach mandate and PDCs or security cheques collected (where required).
              </Typography>
              <div className="button-block">
                <Button variant="contained" color="primary" size="large" onClick={() => setOpened(true)}>
                  TELL ME MORE
                </Button>
              </div>
            </Grid>
            <Grid item md={3}>
              <Sidebar sidebar={HowItWorks} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default DisbursementSigningAgreement;
