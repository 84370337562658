import React, { useEffect, useContext } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import { GetStarted } from "../utils/SidebarMenu";
import { UserContext } from "../context/UserContext";

function TheProcess(props) {
  const { setOpened } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="The Process" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              <Typography variant="body1">
                NBFC’s &amp; Institutional lenders can register on FaircentPro as lenders after providing some basic information and paying the registration fee. Our team will contact them for
                documents required for KYC verification. Once the verification process is completed, the platform will provide the institutional lenders with maker and checker access to their unique
                dashboard ensuring seamless lending experience. Lender on-boarding and dashboard access is supported through multiple devices such as iOS, android and desktop.
              </Typography>
              <Typography variant="body1">
                The dashboard will be the lender’s window to access loan listings of pre-verified borrowers, all transactions, reports, analytics and to monitor loan portfolio. Loan listings are
                visible on the lender’s dashboard along with relevant financial, credit and personal details of each borrower. Lenders can also run the information provided though their own credit
                evaluation algorithms, if desired.{" "}
              </Typography>
              <Typography variant="body1">
                To start investing by sending proposals to the borrowers, the lender’s escrow account must be pre-funded with the amount he wishes to invest (which shall not be less than the minimum
                first recharge amount). Once the loan is funded, a formal contract is signed by the lender and the borrower. The loan amount is then transferred to the borrower’s account from the
                lender’s escrow account and the borrower makes periodic repayments via EMI over the stipulated time period in the Agreement. Lenders can track the repayments through their dashboard.
                Faircent provides a fair, transparent, technology-driven platform for the whole process to happen smoothly and quickly.
              </Typography>
              <div className="button-block">
                <Button variant="contained" color="primary" size="large" onClick={() => setOpened(true)}>
                  TELL ME MORE
                </Button>
              </div>
            </Grid>
            <Grid item md={3}>
              <Sidebar sidebar={GetStarted} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default TheProcess;
