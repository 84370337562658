import React from "react";
import { Container, Grid, Typography } from "@mui/material";

function PageBanner(props) {
  return (
    <div className="page-banner">
      <Container>
        <Grid container spacing={0}>
          <Grid item md={12}>
            <Typography variant="h2">{props.name}</Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default PageBanner;
