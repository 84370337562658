import React from "react";
import { NavLink } from "react-router-dom";

function Sidebar({ sidebar }) {
  return (
    <div className="sidebar">
      <ul>
        {sidebar.map((item) => (
          <li key={item.id}>
            <NavLink activeClassName="active" to={`${item.link}`}>
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Sidebar;
