import React, { useEffect } from "react";
import { Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import { AboutUs } from "../utils/SidebarMenu";
import { UserContext } from "../context/UserContext";

function TheTeam(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="The Team" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              <div className="team-lists">
                <Grid container spacing={4}>
                  <Grid item md={3}>
                    <div className="team-photo">
                      <picture>
                        <img src="../assets/images/rajat.jpg" alt="" />
                      </picture>
                    </div>
                  </Grid>
                  <Grid item md={9}>
                    <Typography variant="h2">
                      Rajat Gandhi - <span className="normal">Founder &amp; CEO, Faircent</span>
                    </Typography>
                    <Typography variant="body1">
                      A marketer, strategist, and brand builder with over 20 years of experience, Rajat Gandhi is the Founder &amp; CEO of Faircent, India’s largest peer-to-peer lending platform. As
                      one of the earliest Internet professionals in India, Rajat has leveraged his extensive expertise in online and digital realms to pioneer the concept of online peer-to-peer
                      lending in India and establish Faircent as the largest P2P lending platform in the country.
                    </Typography>
                    <Typography variant="body1">
                      Through Faircent, Rajat has been playing a critical role in extending the benefits of financial inclusion to India’s traditionally underserved populace and democratising debt by
                      enabling access to cheap and fast credit for borrowers including the SME/MSME segment. It also provides a new asset class to investors looking to maximise returns on their idle
                      funds. This focus on offering greater value for all stakeholders in the lending value chain has been the key driver behind Faircent’s exponential growth.
                    </Typography>
                    <Typography variant="body1">
                      Rajat holds a Master’s degree in Business Administration, and has been involved in launching and building online portals and communities as well as e-commerce, classifieds, and
                      digital advertising businesses for some of the leading organisations in the country. He led multiple initiatives during his time at The Times Group and was deeply involved in the
                      launch of new portals like Timesjobs.com, Magicbricks.com, and Simplymarry.com as a founding member. Before founding Faircent in 2013, he was associated with Performics, a
                      digital marketing agency of Chicago-based Publicis Group, as its Senior Vice President.
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <div className="team-lists">
                <Grid container spacing={4}>
                  <Grid item md={3}>
                    <div className="team-photo">
                      <picture>
                        <img src="../assets/images/vinay.jpg" alt="" />
                      </picture>
                    </div>
                  </Grid>
                  <Grid item md={9}>
                    <Typography variant="h2">
                      Vinay Mathews - <span className="normal">Founder &amp; COO, Faircent</span>
                    </Typography>
                    <Typography variant="body1">
                      Vinay Mathews is the Founder &amp; COO at Faircent, India’s largest peer-to- peer lending platform. Having pioneered the concept of online peer-to-peer lending in India with
                      Faircent, Vinay has been instrumental in the company’s growth since its inception. His long and successful track record in driving business and revenue growth for internet
                      businesses has helped him establish Faircent as the largest P2P lending platform in the country.
                    </Typography>
                    <Typography variant="body1">
                      An expert with over 20 years of experience in scaling up operations and delivering profitable growth for internet businesses, Vinay holds a Master’s degree in Business
                      Administration from the Institute of Management Studies, Devi Ahilya Vishwavidyalaya. He has held leadership positions with several renowned organisations like The Indian
                      Express, Rediff.com, Sify, and Bennett, Coleman, and Co. Ltd over the course of his career. Prior to founding Faircent, he was serving as National Head and Associate Vice
                      President – Sales &amp; Service Delivery for TimesJobs.com.
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <div className="team-lists">
                <Grid container spacing={4}>
                  <Grid item md={3}>
                    <div className="team-photo">
                      <picture>
                        <img src="../assets/images/karun.jpg" alt="" />
                      </picture>
                    </div>
                  </Grid>
                  <Grid item md={9}>
                    <Typography variant="h2">
                      Karun Thareja - <span className="normal">Chief Product Officer</span>
                    </Typography>
                    <Typography variant="body1">
                      Karun has extensive experience in Marketing, Sales and Business Management spanning more than 20 years. His key forte is taking businesses down the route of exponential growth
                      and has demonstrated the same in his previous assignments at both start-ups and global market share leaders. His domain expertise includes Analytics, Enterprise Systems, Contact
                      Center Management and Process Management. He was last part of the leadership at an analytics startup called WyzMindz. In his prior roles he has led multi-fold growth in business
                      units at companies like IBM, Microsoft, Oracle, Dassault Systems and NIIT.
                    </Typography>
                    <Typography variant="body1">Karun makes admirable sketches and loves to paint.</Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item md={3}>
              <Sidebar sidebar={AboutUs} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default TheTeam;
