import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
// import { withStyles } from "@mui/styles";
import { Button, Dialog, Typography, TextField, Select, InputLabel, MenuItem, FormControl, DialogTitle, DialogContent, DialogActions } from "@mui/material";
// import MuiDialogTitle from "@material-ui/core/DialogTitle";
// import MuiDialogContent from "@material-ui/core/DialogContent";
// import IconButton from "@material-ui/core/IconButton";
// import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { styled } from "@mui/material/styles";

import { state } from "./form/state";
import { useNavigate } from "react-router-dom";
// import { withStyles } from "@mui/material";

// const styles = (theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(2),
//   },
//   closeButton: {
//     position: "absolute",
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },
// });

// const DialogTitle = withStyles(styles)((props) => {
//   const { children, classes, onClose, ...other } = props;
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       <Typography variant="h6">{children}</Typography>
//       {onClose ? (
//         <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// });

// const DialogContent = withStyles((theme) => ({
//   root: {
//     padding: theme.spacing(2),
//   },
// }))(MuiDialogContent);

const validationSchema = yup.object({
  email: yup.string("Enter your company email.").email("Enter a valid company email.").required("Your company email is required"),
  full_name: yup
    .string()
    .required("Please enter the your full name.")
    .matches(/[a-zA-Z ]+$/g, "Please enter a valid full name with only alphabets."),
  mobile: yup
    .string()
    .required("Please enter your mobile number.")
    .min(10, "Please enter a valid 10 digit mobile number.")
    .max(10, "Please enter a valid 10 digit mobile number.")
    .matches(/[0-9]{10}/, "Please enter a valid mobile number with only numbers."),
  company_name: yup
    .string()
    .required("Please enter your company name.")
    .matches(/[a-zA-Z ]+$/g, "Please enter a valid company name with only alphabets."),
  password: yup.string().required("Please enter a password."),
  state: yup.string().required("Please select a State."),
  city: yup.string().required("Please enter a City name."),
  aum: yup.string().required("Please select the AUM"),
  is_nbfc_registered: yup.string().required("Your company needs to be registered as an NBFC with RBI to lend on FaircentPro."),
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function TellMeMore(props) {
  // const history = useHistory();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      user: "cube",
      full_name: "",
      mobile: "",
      company_name: "",
      email: "",
      password: "cube@123",
      state: "",
      city: "",
      aum: "",
      is_nbfc_registered: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setTimeout(() => {
        //setSubmitting(true);
        axios({
          method: "POST",
          url: "https://www.faircent.com/faircent_pro_lead_data",
          data: {
            user: "cube",
            full_name: values.full_name,
            mobile: values.mobile,
            company_name: values.company_name,
            email: values.email,
            password: "cube@123",
            state: values.state,
            city: values.city,
            aum: values.aum,
            is_nbfc_registered: values.is_nbfc_registered,
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            //setSubmitting(false);
            // console.log("++++++++++++++++++++++++++++++", response);
            // console.log("status+++++++++++++++", response.data.status);
            if (response.data.status == "SUCCESS") {
              props.setOpened(false);
              // history.push("/contact-successfull");
              navigate("/contact-successfull");
            }
          })
          .catch((error) => {
            //setSubmitting(false);
            console.log(error.response);
          });
        //setSubmitting(false);
      }, 500);

      //console.log("new values", values);
    },
  });

  const handleClickOpen = () => {
    props.setOpened(true);
  };
  const handleClose = () => {
    props.setOpened(false);
  };

  return (
    <div className="tell-me-form">
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.opened}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          My Contact Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-row">
              <TextField
                fullWidth
                id="name"
                name="full_name"
                label="Your Full Name"
                value={formik.values.full_name}
                onChange={formik.handleChange}
                error={formik.touched.full_name && Boolean(formik.errors.full_name)}
                helperText={formik.touched.full_name && formik.errors.full_name}
              />
            </div>
            <div className="form-row">
              <TextField
                fullWidth
                id="mobile"
                name="mobile"
                label="Your Mobile Number"
                value={formik.values.mobile}
                onChange={formik.handleChange}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
            </div>
            <div className="form-row">
              <TextField
                fullWidth
                id="company_name"
                name="company_name"
                label="Your Company Name"
                value={formik.values.company_name}
                onChange={formik.handleChange}
                error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                helperText={formik.touched.company_name && formik.errors.company_name}
              />
            </div>
            <div className="form-row">
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Your Company E-Mail"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
            <div className="form-row">
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="state">State</InputLabel>
                <Select
                  labelId="state"
                  id="state"
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                >
                  {state.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="form-row">
              <TextField
                fullWidth
                id="city"
                name="city"
                label="City"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </div>
            <div className="form-row">
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="aum">How much is the AUM (Assets Under Management) of your company?</InputLabel>
                <Select
                  labelId="aum"
                  id="aum"
                  name="aum"
                  value={formik.values.aum}
                  onChange={formik.handleChange}
                  error={formik.touched.aum && Boolean(formik.errors.aum)}
                  helperText={formik.touched.aum && formik.errors.aum}
                >
                  <MenuItem value={"Rs 50.0 Lakhs - Rs 1. 0 Crore"}>Rs 50.0 Lakhs - Rs 1. 0 Crore</MenuItem>
                  <MenuItem value={"Rs 1.0 Crore - Rs 2.0 Crore"}>Rs 1.0 Crore - Rs 2.0 Crore</MenuItem>
                  <MenuItem value={"Rs 2.0 Crore - Rs 5.0 Crore"}>Rs 2.0 Crore - Rs 5.0 Crore</MenuItem>
                  <MenuItem value={"Rs 5.0 Crore - Rs 15.0 Crore"}>Rs 5.0 Crore - Rs 15.0 Crore</MenuItem>
                  <MenuItem value={"Rs 15.0 Crore - Rs 30.0 Crore"}>Rs 15.0 Crore - Rs 30.0 Crore</MenuItem>
                  <MenuItem value={" Rs 30.0 Crore - Rs 50.0 Crore"}>Rs 30.0 Crore - Rs 50.0 Crore</MenuItem>
                  <MenuItem value={"Rs 50.0 Crore - Rs 100.0 Crore"}>Rs 50.0 Crore - Rs 100.0 Crore</MenuItem>
                  <MenuItem value={"Rs 100 Crore+"}>Rs 100 Crore+</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="form-row">
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="is_nbfc_registered">Is your company registered as an NBFC by the RBI?</InputLabel>
                <Select
                  labelId="is_nbfc_registered"
                  id="is_nbfc_registered"
                  name="is_nbfc_registered"
                  value={formik.values.is_nbfc_registered}
                  onChange={formik.handleChange}
                  error={formik.touched.is_nbfc_registered && Boolean(formik.errors.is_nbfc_registered)}
                  helperText={formik.touched.is_nbfc_registered && formik.errors.is_nbfc_registered}
                >
                  <MenuItem value={"Yes"}>Yes</MenuItem>
                  <MenuItem value={"no"}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="form-row">
              <Button color="primary" variant="contained" fullWidth type="submit" size="large">
                SUBMIT
              </Button>
            </div>
          </form>
        </DialogContent>
      </BootstrapDialog>

      {/* <Dialog onClose={() => props.setOpened(false)} aria-labelledby="customized-dialog-title" open={props.opened} maxWidth="lg">
        <DialogTitle id="customized-dialog-title" onClose={() => props.setOpened(false)}>
          My Contact Details
        </DialogTitle>

        <DialogContent dividers>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-row">
              <TextField
                fullWidth
                id="name"
                name="full_name"
                label="Your Full Name"
                value={formik.values.full_name}
                onChange={formik.handleChange}
                error={formik.touched.full_name && Boolean(formik.errors.full_name)}
                helperText={formik.touched.full_name && formik.errors.full_name}
              />
            </div>
            <div className="form-row">
              <TextField
                fullWidth
                id="mobile"
                name="mobile"
                label="Your Mobile Number"
                value={formik.values.mobile}
                onChange={formik.handleChange}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
            </div>
            <div className="form-row">
              <TextField
                fullWidth
                id="company_name"
                name="company_name"
                label="Your Company Name"
                value={formik.values.company_name}
                onChange={formik.handleChange}
                error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                helperText={formik.touched.company_name && formik.errors.company_name}
              />
            </div>
            <div className="form-row">
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Your Company E-Mail"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
            <div className="form-row">
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="state">State</InputLabel>
                <Select
                  labelId="state"
                  id="state"
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                >
                  {state.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="form-row">
              <TextField
                fullWidth
                id="city"
                name="city"
                label="City"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </div>
            <div className="form-row">
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="aum">How much is the AUM (Assets Under Management) of your company?</InputLabel>
                <Select
                  labelId="aum"
                  id="aum"
                  name="aum"
                  value={formik.values.aum}
                  onChange={formik.handleChange}
                  error={formik.touched.aum && Boolean(formik.errors.aum)}
                  helperText={formik.touched.aum && formik.errors.aum}
                >
                  <MenuItem value={"Rs 50.0 Lakhs - Rs 1. 0 Crore"}>Rs 50.0 Lakhs - Rs 1. 0 Crore</MenuItem>
                  <MenuItem value={"Rs 1.0 Crore - Rs 2.0 Crore"}>Rs 1.0 Crore - Rs 2.0 Crore</MenuItem>
                  <MenuItem value={"Rs 2.0 Crore - Rs 5.0 Crore"}>Rs 2.0 Crore - Rs 5.0 Crore</MenuItem>
                  <MenuItem value={"Rs 5.0 Crore - Rs 15.0 Crore"}>Rs 5.0 Crore - Rs 15.0 Crore</MenuItem>
                  <MenuItem value={"Rs 15.0 Crore - Rs 30.0 Crore"}>Rs 15.0 Crore - Rs 30.0 Crore</MenuItem>
                  <MenuItem value={" Rs 30.0 Crore - Rs 50.0 Crore"}>Rs 30.0 Crore - Rs 50.0 Crore</MenuItem>
                  <MenuItem value={"Rs 50.0 Crore - Rs 100.0 Crore"}>Rs 50.0 Crore - Rs 100.0 Crore</MenuItem>
                  <MenuItem value={"Rs 100 Crore+"}>Rs 100 Crore+</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="form-row">
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="is_nbfc_registered">Is your company registered as an NBFC by the RBI?</InputLabel>
                <Select
                  labelId="is_nbfc_registered"
                  id="is_nbfc_registered"
                  name="is_nbfc_registered"
                  value={formik.values.is_nbfc_registered}
                  onChange={formik.handleChange}
                  error={formik.touched.is_nbfc_registered && Boolean(formik.errors.is_nbfc_registered)}
                  helperText={formik.touched.is_nbfc_registered && formik.errors.is_nbfc_registered}
                >
                  <MenuItem value={"Yes"}>Yes</MenuItem>
                  <MenuItem value={"no"}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="form-row">
              <Button color="primary" variant="contained" fullWidth type="submit" size="large">
                SUBMIT
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog> */}
    </div>
  );
}
