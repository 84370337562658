import React, { useEffect, useContext } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import { HowItWorks } from "../utils/SidebarMenu";
import { UserContext } from "../context/UserContext";

function WhatFaircentProDoes(props) {
  const { setOpened } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="What FaircentPro does not do" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              <Typography variant="body1">
                Decisions to lend on this portal are entirely at the discretion of the NBFCs/institutional lenders. We do not guarantee fixed or minimum rate of returns to any lender. Nor do we
                guarantee repayment of the principal amount to lenders. We facilitate the decision-making process by providing relevant information about the borrowers and lenders to each other.
              </Typography>
              <Typography variant="body1">
                We encourage all borrowers and lenders to read the FaircentPro Privacy Policy and Terms and Conditions on the website clearly before registering and contact us for any clarifications.
              </Typography>
              <Typography variant="body1">
                Following our philosophy of being a neutral platform, we do not give any financial advice or recommendations to either borrowers or lenders on this forum. Members are advised to make
                their own informed decisions and transact accordingly, free of any coercion or any form of influence from us.
              </Typography>
              <div className="button-block">
                <Button variant="contained" color="primary" size="large" onClick={() => setOpened(true)}>
                  TELL ME MORE
                </Button>
              </div>
            </Grid>
            <Grid item md={3}>
              <Sidebar sidebar={HowItWorks} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default WhatFaircentProDoes;
