import React, { useEffect, useContext } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import { GetStarted } from "../utils/SidebarMenu";
import { UserContext } from "../context/UserContext";

function GetInTouchGetStarted(props) {
  const { setOpened } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="Get in touch to get started" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              <Typography variant="body1">It's simple to get started.</Typography>
              <Typography variant="body1">
                Just inform us about your interest to explore lending on the FaircentPro platform by getting in touch with us via our 'Contact us' form. Within 24 hours, you will recieve a call from
                our representative to set up a detailed discussion on your requirements within 24 hours.
              </Typography>
              <div className="button-block">
                <Button variant="contained" color="primary" size="large" onClick={() => setOpened(true)}>
                  Contact Us
                </Button>
              </div>
              <Typography variant="body1">Do note that the below documents will be required to get you registered to lend on FaircentPro, so make sure you have them handy.</Typography>
              <div className="orderlists">
                <ol>
                  <li>Copy of NBFC Certificate of Registration issued by RBI </li>
                  <li>Copy of Pan Card of Company</li>
                  <li>Copy of MCA Registration</li>
                  <li>NBFC letterhead with logo for sanction letter template.</li>
                  <li>Names of Directors on the Board</li>
                  <li>List of Signatory Directors</li>
                  <li> Incorporation Date</li>
                  <li>NBFC-Faircent Agreement Signed Online</li>
                  <li>Grievance Redressal Procedure</li>
                  <li> Contact Details of NBFC SPOC</li>
                  <li> NBFC Bank Account Details</li>
                </ol>
              </div>
            </Grid>
            <Grid item md={3}>
              <Sidebar sidebar={GetStarted} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default GetInTouchGetStarted;
