import React, { useEffect, useContext } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import { AboutUs } from "../utils/SidebarMenu";
import { UserContext } from "../context/UserContext";

function TermsOfUse(props) {
  const { setOpened } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="Terms of Use" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              <Typography variant="body1">
                Faircentpro.com ("FaircentPro" or "Site" or "we" or "our") a trade name owned and managed by FTIPL Payments and Marketing Tech Pvt. Ltd. is a web portal that provides a platform to
                bring together people who are willing to borrow and NBFC’s and Financial Institutions who are willing to lend to the borrowers. FaircentPro is an attempt to build a close community
                that would share its resources with each other in all honesty and promote togetherness and growth of the community at an individual level and of the community as a whole. On this web
                portal, a visitor to the website could browse and search for NBFC’s and Financial Institutions who are willing to lend and people who are willing to borrow, details of service
                providers such as verification agencies, collection agents and other such service providers that would provide services to either lenders or to borrowers. FaircentPro merely
                facilitates a transaction of borrowing and lending between two such parties. FaircentPro neither lends nor borrows on its own through this website. FaircentPro is neither a financial
                institution, nor runs a collective investment scheme or a chit fund or any other such financial scheme.
              </Typography>
              <Typography variant="body1">
                <strong>SITE:</strong> Site means FaircentPro.com which is owned and managed by FTIPL Payments and Marketing Tech Private Limited.
              </Typography>
              <Typography variant="body1">
                <strong>YOU/YOUR:</strong> You/Your in case of an individual shall mean natural or legal person who is at least 18 years of age and could legally enter into a contract in India and
                could use the services and products provided on FaircentPro.com in accordance with the terms and conditions recorded hereinafter and has agreed to become a paid member of this Site and
                whose membership fees is not in arrears.
              </Typography>
              <Typography variant="body1">And </Typography>
              <Typography variant="body1">
                In case of a NBFC/Institutional Lender shall mean a company registered under the Companies Act, 1956/2013 (as the case may be) and could legally enter into a contract in India and
                could use the services and products provided on FaircentPro.com in accordance with the terms and conditions recorded hereinafter and has agreed to become a paid member of this Site and
                whose membership fees is not in arrears.
              </Typography>
              <Typography variant="body1">
                <strong>Acceptance of Terms and Conditions</strong>
              </Typography>
              <Typography variant="body1">
                Every effort has been made to ensure, that the information contained on the Site is correct, however FaircentPro makes no warranty as the accuracy, comprehensiveness, or correctness of
                any Service(s) or a Product(s) on the Site, and provides all Service(s) and Product(s) on an "as is" basis. By using this Site, you ("you" or the "End User") agree to these terms and
                conditions ("Terms and Conditions" or "T&amp;C" or "Terms") that we have provided. If you do not wish to agree to these Terms and Conditions, please refrain from using this Site.
              </Typography>
              <Typography variant="body1">
                The Service(s) or a Product(s) and information displayed on the Site is an "invitation to offer" to the End User. Your order for purchase of either Service(s) or Product(s) constitutes
                your "offer" which shall be subject to the terms and conditions as listed below. FaircentPro reserves the right to accept or reject your offer, without providing any reasons. If you
                have supplied us with your valid email address, we will notify you by email as soon as possible to confirm receipt of your instruction and email you again to confirm details and
                therefore process the instructions accordingly for provision of Services(s) or Products(s). Our acceptance of your instructions for either rendition of services or provision of any
                product will take place upon rendition of Service(s) or dispatch of the Product(s) ordered. No act or omission of FaircentPro prior to the actual dispatch of the Product(s) or
                rendition of services ordered will constitute acceptance of your offer. Similarly, no act or omission of FaircentPro prior to the actual rendition of services shall constitute
                acceptance of your offer. A Person who cannot enter into legally binding contract in accordance with the provisions of the Indian Contract Act, 1872 including person below the age of
                18 years or of unsound mind and un-discharged insolvents etc. are not entitled to use the Site.
              </Typography>
              <Typography variant="body1">
                Persons under the age of 18 years are not permitted to get engaged with this Site and have no permission to call for any services or products or to lend or to borrow from through this
                Site.
              </Typography>
              <Typography variant="body1">
                The Site is freely accessible to the End User however; the End User will have to register with FaircentPro.com and create their personal registration account prior to any purchase of
                Service(s) or Product(s). All registration information must be correct, complete and promptly updated by the End User each time it changes. FaircentPro reserves the right to make any
                changes to our Terms and Conditions and/or our Privacy Policy (which is incorporated herein by reference) as we deem necessary or desirable without prior notification to you. We
                suggest to you, therefore, that you read our T&amp;C and Privacy Policy from time to time in order that you stay informed as to any such changes. If we make changes to our T&amp;C and
                Privacy Policy and you continue to use our Site, you are impliedly agreeing to the revised T&amp;C and Privacy Policy expressed herein.
              </Typography>
              <div className="unorderlists">
                <ul>
                  <li>Your privacy is important to us and we will protect it. We will not share your personal information with anyone other than those listed in our Privacy Policy.</li>
                  <li>
                    As a condition of purchase, FaircentPro requires your consent to send you administrative and promotional emails / SMS/ Phone Calls. We will send you information regarding your
                    account activity and purchases, as well as updates about our Services and Products and promotional offers. You can opt-out of our promotional emails anytime by clicking the
                    unsubscribe link at the bottom of any of our email correspondences. Please see our Privacy Policy for details.
                  </li>
                  <li>By placing an order, you make an offer to us to purchase Service(s) or Product(s) you have selected based on the Terms and Conditions stated below.</li>
                  <li>
                    You are required to create an account in order to purchase any Services or a Product from our Site. This is required so we can provide you with easy access to print your orders,
                    view your past purchases, and modify your preferences.
                  </li>
                  <li>If you have any questions about these Terms and Conditions, please contact FaircentPro on support@faircentpro.com</li>
                </ul>
              </div>
              <Typography variant="body1">To read the complete FaircentPro Terms and Conditions, please see below.</Typography>
              <Typography variant="h3">1. ACCEPTANCE OF TERMS AND CONDITIONS</Typography>
              <Typography variant="body1">
                By using this Site, you agree to the Terms and Conditions that FaircentPro has provided. If you do not wish to agree to these Terms and Conditions, please refrain from using the Site.
                In the Site, FaircentPro provides End Users with access to database of lenders, borrowers and services providers in relation to debt services such as verification agencies and
                collection agencies etc. FaircentPro is only a platform that brings together borrowers and lenders, it matches the need of borrowers and lenders, and provides link to service providers
                that are associated with the business of borrowing and lending such as verification agency and collection agency. FaircentPro in no manner collects deposits from the lenders or person
                who would like to earn interest on their money by lending. FaircentPro only supports the lender in verifying the whereabouts of the borrower on best effort basis.
              </Typography>
              <Typography variant="body1">
                If this T&amp;C conflicts with any other document, the T&amp;C will prevail for the purposes of usage of the Site. If you do not agree to be bound by this T&amp;C and the Privacy
                Policy, you may not use the Site in any way.
              </Typography>
              <Typography variant="h3">2. Description of Products &amp; Services</Typography>
              <Typography variant="body1">
                In the Site, FaircentPro provides End Users with access to lenders, borrowers, service providers to borrowers, partners who facilitate lending and provide credit reports for lenders
                and borrowers.
              </Typography>
              <Typography variant="body1">
                Upon your clicking "yes" in the options menu we shall be entitled to send you regular updates via email/ sms/ phone call or any other means regarding our Services and Product
                offerings.
              </Typography>
              <Typography variant="h3">3. General</Typography>
              <Typography variant="body1">
                These terms and conditions that apply to the user of this Site. By using this Site (other than to read this T&amp;C for the first time), user agrees to comply with all of the terms and
                conditions hereof. The right to use this Site is personal to the user and is not transferable to any other person or entity. User shall be responsible for protecting the
                confidentiality of User's password(s), if any. User acknowledges that, although the internet is often a secure environment, sometimes there are interruptions in service or events that
                are beyond the control of FaircentPro, and FaircentPro shall not be responsible for any data lost while transmitting information on the internet. While it is FaircentPro’s objective is
                to make the Site accessible 24 hours per day, 7 days per week, the Site may be unavailable from time to time for any reason including, without limitation, routine maintenance. You
                understand and acknowledge that due to circumstances both within and outside of the control of FaircentPro, access to the Site may be interrupted, suspended or terminated from time to
                time. FaircentPro shall have the right at any time to change or discontinue any aspect or feature of FaircentPro, including, but not limited to, content, hours of availability and
                equipment needed for access or use. Further, FaircentPro may discontinue disseminating any portion of information or category of information, may change or eliminate any transmission
                method and may change transmission speeds or other signal characteristics.
              </Typography>
              <Typography variant="h3">4. Modified Terms</Typography>
              <Typography variant="body1">
                FaircentPro reserves the right at all times to discontinue or modify any of the Terms and Conditions as may be deemed necessary or desirable without prior notification to you. Such
                changes may include, among other things, the adding of certain fees or charges. We suggest to you, therefore, that you re-read this important notice containing our Terms and Conditions
                from time to time in order that you stay informed as to any such changes. If we make changes to our Terms and Conditions and you continue to use our Site, you are impliedly agreeing to
                the Terms and Conditions and Privacy Policy expressed herein. Any such deletions or modifications shall be effective immediately upon FaircentPro posting thereof. Any use of
                FaircentPro by End User after such notice shall be deemed to constitute acceptance by End User of such modifications.
              </Typography>
              <Typography variant="h3">5. License and Site Access</Typography>
              <Typography variant="body1">
                FaircentPro grants you a limited license to access and make use of the Site. This license does not include any downloading or copying of account information for the benefit of another
                vendor or any other third party; caching, unauthorized hypertext links to the Site and the framing of any Content available through the Site, uploading, posting, or transmitting any
                content that you do not have a right to make available (such as the intellectual property of another party); uploading, posting, or transmitting any material that contains software
                viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;
                any action that imposes or may impose (in FaircentPro’s sole discretion) an unreasonable or disproportionately large load on FaircentPro’s infrastructure; or any use of data mining,
                robots, or similar data gathering and extraction tools. You may not bypass any measures used by Faircent to prevent or restrict access to the Site. Any unauthorized use by you shall
                terminate the permission or license granted to you by FaircentPro. By using this Site and by accepting to agree to these terms and conditions you also agree that you shall not hack or
                otherwise hire some third party to hack the site or use any other unauthorized means without a written consent of FaircentPro and access any third party information. By using this Site
                you agree that you will hold all any information received by you during your interaction with this Site or any other users of this Site (including other lenders and borrowers on this
                Site) in the strictest confidence and shall not disclose or use the information obtained by you by accessing this Site. You shall in such an event be liable of a criminal offence and
                Faircent shall institute appropriate legal proceedings to claim damages. FaircentPro also reserve rights to institute proceedings in accordance with the law relating to intellectual
                property rights violation or infringement available to it in case the misuse of this website is a person or an entity that belongs to out of India. In any event, the jurisdiction to
                institute any protective proceedings shall vest with the Faircent within the jurisdiction of gurgaon.
              </Typography>
              <Typography variant="body1">
                Our website users may experience difficulty in accessing website from 0300 am to 0330 am due to website updates. We apologize for any inconvenience caused to our Users.
              </Typography>
              <Typography variant="h3">6. Faircent Usage Eligibility</Typography>
              <Typography variant="body1">
                The FaircentPro.com Service is not available to persons under the age of 18 or of unsound mind or who are declared insolvent or to any Users suspended or removed from the system by
                FaircentPro for any reason. Users may not have more than one active account. Additionally, Users are prohibited from selling, trading, or otherwise transferring their Faircentpro.com
                account to another party. If you do not qualify, you may not use the Faircentpro.com or the Site.
              </Typography>
              <Typography variant="h3">7. Your Account</Typography>
              <Typography variant="body1">
                In consideration of your use of the Site, you represent that you are of legal age to form a binding contract and are not a person barred from receiving services or goods under the laws
                as applicable in India. Additionally, you are more than 18 years of age. You also agree to provide true, accurate, current and complete information about yourself as prompted by the
                Site's registration form. If you provide any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or
                FaircentPro has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, FaircentPro has the right to suspend or terminate your account and
                refuse any and all current or future use of the Site (or any portion thereof). As FaircentPro is a website that enables borrowers to find lenders and lenders to find borrowers, it
                considers provisions of wrong information on this web portal willingly an act that would constitute misrepresentation, fraud and cheating. If you use the Site, you are responsible for
                maintaining the confidentiality of your account and password, and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your
                account or password. Because of this, we strongly recommend that you exit from your account at the end of each session. You agree to notify FaircentPro immediately of any unauthorized
                use of your account or any other breach of security. FaircentPro reserves the right to refuse service, terminate accounts, or remove or edit content in its sole discretion. In the
                event, FaircentPro finds that the information shared by the User of the website is incorrect or that the User is in breach of terms or conditions of the website or User is in default
                of the lenders and borrowers agreement than in such case FaircentPro may add the name and details of such person in the list of persons who are blacklisted from receiving funds or
                lending.
              </Typography>
              <Typography variant="body1">
                By registering with us and opening an account on our platform, whether as a Lender or a Borrower, you hereby authorize, allow and permit FaircentPro under this specific authorization,
                to identify prospective lenders and/ or borrowers for your benefit as per the set risk criteria (that you as a Lender may select to choose a Borrower), and to conclude a loan
                transaction on behalf of a Lender and on behalf of a Borrower and thereafter to execute a Lender Borrower agreement on your behalf (as a Lender and/or a Borrower, as the case may be).
                You (as a Lender or a Borrower) hereby further authorize FaircentPro to do all such acts, deeds and things relating to lending Your money (as a Lender to a Borrower) and collecting
                repayments (as a Borrower for the benefit of a Lender). All details about lending and repayment conducts by FaircentPro will be available on Your personal dashboards provided to You by
                FaircentPro.{" "}
              </Typography>
              <Typography variant="h3">8. Equipment</Typography>
              <Typography variant="body1">
                User shall be responsible for obtaining and maintaining all telephone, computer hardware and other equipment needed for access to and use of this Site and all charges related thereto.
                FaircentPro shall not be liable for any damages to the End User's equipment resulting from the use of this Site.
              </Typography>
              <Typography variant="body1">9. End User conduct</Typography>
              <Typography variant="body1">
                This Site and any individual sites or merchant-specific, city-specific, or state-specific sites now or hereinafter contained within or otherwise available through external hyperlinks
                with our Site (the "Microsites") are private property. All interactions on this Site and/or the Microsites must comply with these Terms and Conditions. Although we welcome and
                encourage user interaction on our Site, we do insist and require that all End Users restrict any and all activity in connection with the use of this Site and the Microsites to that
                which involves lawful purposes only. End User shall not post or transmit through this Site any material which violates or infringes in any way upon the rights of others, or any
                material which is unlawful, threatening, abusive, defamatory, invasive of privacy or publicity rights, vulgar, obscene, profane or otherwise objectionable, which encourages conduct
                that would constitute a criminal offense, give rise to civil liability or otherwise violate any law, or which, without FaircentPro's express prior, written approval, contains
                advertising or any solicitation with respect to Products or Services. Any conduct by an End User that in FaircentPro's exclusive discretion restricts or inhibits any other End User
                from using or enjoying this Site and/or any of the Microsites is strictly prohibited. End User shall NOT use this Site or any of the Microsites to advertise or perform any commercial,
                religious, political or non-commercial solicitation, including, but not limited to, the solicitation of users of this Site and/or the Microsites to become users of other on- or offline
                services directly or indirectly competitive or potentially competitive with FaircentPro.
              </Typography>
              <Typography variant="body1">
                The foregoing provisions of this Section apply equally to and are for the benefit of FaircentPro, its subsidiaries, affiliates and its third party content providers and licensors, and
                each shall have the right to assert and enforce such provisions directly or on its own behalf.
              </Typography>
              <Typography variant="body1">
                Any and all information howsoever exchanged (including but not limited to, publication over dashboard, or personal email communication) between FaircentPro (through its employees,
                representatives etc.,) and You as a registered Lender or a registered Borrower, or between You (as a Lender or as a Borrower) with another User who is a Lender or a Borrower, is
                personal and confidential to each User registered with Us as well as for FaircentPro and you are required to maintain strict confidentiality of this information. Any unauthorized
                disclosure, or any disclosure (including but not limited to posting such information on any social media platforms, otherwise) without the express written consent of FaircentPro or the
                Lender or the Borrower, as the case may be, shall be considered a serious breach of your obligations under these Terms and Conditions as well as the Lender – Borrower Agreement
                executed pursuant to a successful loan transaction. Such breach by You shall entitle FaircentPro to forthwith terminate your registration (either as a Lender or a Borrower) and seek
                damages from You under applicable law.
              </Typography>
              <Typography variant="h3">10. Monitoring</Typography>
              <Typography variant="body1">
                FaircentPro shall have the right, but not the obligation, to monitor the content of the Site at all times, including any chat rooms and forums that may hereinafter be included as part
                of the Site, to determine compliance with this Agreement and any operating rules established by FaircentPro, as well as to satisfy any applicable law, regulation or authorized
                government request. Without limiting the foregoing, FaircentPro shall have the right to remove any material that FaircentPro, in its sole discretion, finds to be in violation of the
                provisions hereof or otherwise objectionable.
              </Typography>
              <Typography variant="h3">11. License Grant</Typography>
              <Typography variant="body1">
                By posting communications on or through this Site, User shall be deemed to have granted to FaircentPro a royalty-free, perpetual, irrevocable, non-exclusive license to use, reproduce,
                modify, publish, edit, translate, distribute, perform, and display the communication alone or as part of other works in any form, media, or technology whether now known or hereafter
                developed, and to sublicense such rights through multiple tiers of sub-licensees.
              </Typography>
              <Typography variant="h3">12. Personal Information</Typography>
              <Typography variant="body1">
                In order to avail of the Services from the Site, you will need to provide your details prior to get enlisted either as a borrower or as a lender. As an account holder you are required
                to provide FaircentPro with accurate personal information. Provision of another person’s details as your own will not be deemed acceptable.
              </Typography>
              <Typography variant="body1">
                Any personal information collected by FaircentPro shall not be disclosed to any third party unrelated to the FaircentPro business unless such a disclosure is authorized or required by
                law, or you have expressly consented to such a disclosure. However, by accepting the terms and conditions you grant FaircentPro an unconditional right to share your personal
                information with its’s partners, affiliates, employees and agents for administrative purpose. Please also see the Privacy Policy in this regard.
              </Typography>
              <Typography variant="h3">13. Credit Card Information</Typography>
              <Typography variant="body1">
                You agree, understand and confirm that the credit card details provided by you for availing of services on FaircentPro will be correct and accurate and you shall not use the credit
                card which is un-lawfully owned by you, thus you must not pay or attempt to pay for services using fraudulent credit card information. You further agree and undertake to provide the
                correct and valid credit card details to FaircentPro. In case of orders that amount to Rs.10,000 or more, the customer will be required to provide the last four digits of the credit
                card used to place the order, for the purpose of verification.
              </Typography>
              <Typography variant="body1">
                Further the said information will not be utilized and shared by FaircentPro with any third parties unless required for fraud verifications or by law, regulation or court order.
                FaircentPro will not be liable for any credit card fraud. The liability for use of a card fraudulently will be on you and the onus to ‘prove otherwise’ shall be exclusively yours.
              </Typography>
              <Typography variant="h3">14. Cancellation by FaircentPro</Typography>
              <Typography variant="body1">
                Please note that there may be certain instruction to remit payments or seek rendition of services that Faircent is unable to accept shall be cancel. We reserve the right, at our sole
                discretion, to refuse or cancel any order for any reason. We may also require additional verifications or information before accepting any instruction or registering you on are portal
                as a lender or a borrower. The borrower(s) and lender(s) are required to note that negative third party verification report(s) are crucial factors considered during the funding
                process. The funding may get rejected at any stage due to lack of information or verifiable information.
              </Typography>
              <Typography variant="h3">15. Fraudulent /Declined Transactions</Typography>
              <Typography variant="body1">
                FaircentPro reserves the right to recover the cost of Service(s) and Product(s), collection charges and lawyers fees from persons using the Site fraudulently. FaircentPro reserves the
                right to initiate legal proceedings against such persons for fraudulent use of the Site and any other unlawful acts or omissions in breach of these terms and conditions.
              </Typography>
              <Typography variant="h3">16. Compliance</Typography>
              <Typography variant="body1">
                By using this Site and registering with us on the Site, you agree that you will comply with any directions issued by the Reserve Bank of India (‘RBI’) or any other statutory authority
                in connection with the Services.
              </Typography>
              <Typography variant="h3">17. Limitation of Liability</Typography>
              <Typography variant="body1">
                FaircentPro, its associates and technology partners make no representations or warranties about the accuracy, reliability, completeness, current-ness and/or timeliness of any content,
                information, software, text, graphics, links or communications provided on or through the use of the Site or that the operation of the Site will be error free and/or uninterrupted.{" "}
              </Typography>
              <Typography variant="body1">
                Consequently, FaircentPro assumes no liability whatsoever for any monetary or other damage suffered by you on account of the delay, failure, interruption, or corruption of any data or
                other information transmitted in connection with use of the Site; and/or any interruption or errors in the operation of the Site. You expressly understand and agree that FaircentPro
                and its subsidiaries, affiliates, officers, employees, agents, partners and licensors shall not be liable to you for any direct, indirect, incidental, special, consequential or
                exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if FaircentPro has been advised of the possibility
                of such damages), resulting from use of the Site, sale and supply of Products content or any related goods and services.
              </Typography>
              <Typography variant="body1">
                This limitation of liability section shall prevail over any conflicting or inconsistent provision contained in any of the documents comprising this Agreement. It is up to you to take
                precautions to ensure that whatever you select for your use is free of such items as viruses, worms, trojan horses and other items of a destructive nature.
              </Typography>
              <Typography variant="body1">
                The User further with immediate effect waives any right which had accrued to him from using the website to initiate any legal action against FaircentPro. User also acknowledge that the
                credibility of a lender or a borrower as depicted upon the website is based on the information that is provided to FaircentPro. In case there is a non-disclosure of information to
                FaircentPro of the information, and the judgment of FaircentPro is impaired and the ratings or credibility of a lender or a borrower is not found to be correct, FaircentPro shall not
                be responsible for any claims or liabilities. FaircentPro in any event is not responsible for any default in return of money that is borrowed from the lender and the lender shall have
                exclusive right to initiate proceedings against the defaulter borrower. FaircentPro does provide the necessary information and support to the lender. However, lender shall not make any
                claim against FaircentPro and shall conduct an independent due diligence of the borrower before lending it to him.
              </Typography>
              <Typography variant="h3">18. Indemnity</Typography>
              <Typography variant="body1">
                You agree to indemnify and hold FaircentPro (and its officers, directors, agents, subsidiaries, joint ventures, and employees) harmless from any claim or demand, including reasonable
                attorneys’ fees, or arising out of or related to your breach of this T&amp;C, or your violation of any law or the rights of a third party including but not limited to breach of any
                warranties, representations or undertakings or in relation to the non-fulfillment of any of your obligations under this T&amp;C or arising out of violation of any applicable laws,
                regulations including but not limited to Intellectual Property Rights, payment of statutory dues and taxes, claim of libel, defamation, violation of rights of privacy or publicity,
                loss of service by other subscribers and infringement of intellectual property or other rights. This clause shall survive the expiry or termination of this T&amp;C. In the event any
                excess amount is credited into your account by reason of any system error or human error (‘Wrongfully Credited Amounts’) to which you are not entitled, you shall immediately notify us
                in writing of the same and shall not withdraw, use, transfer or deal with such Wrongfully Credited Amount in any manner whatsoever. You hereby undertake to indemnify us for any losses,
                costs, damages, charges and expenses that we may suffer as a result of a breach of this obligation.
              </Typography>
              <Typography variant="h3">19. Severability</Typography>
              <Typography variant="body1">
                If any of the Terms shall be deemed void, invalid or unenforceable for any reason, that condition shall be deemed several and shall not affect the enforceability of any remaining
                provision.
              </Typography>
              <Typography variant="h3">20. Customer Communication</Typography>
              <Typography variant="body1">
                FaircentPro will communicate with you through any of the modes using email, SMS and telephone or all of them or any other mode. Account holders must provide a valid email address and
                contact telephone and/ or mobile number to FaircentPro for this purpose. Please note FaircentPro will not be able to contact the customer in case customer’s number is part of DNC (Do
                Not Call) list as generated by TRAI (Telecom Regulatory Authority of India). In this case we request customers to call us on our call center number.
              </Typography>
              <Typography variant="body1">
                When you use the Site or send emails to FaircentPro, you are communicating with FaircentPro electronically. You consent to receive communications from FaircentPro electronically.
              </Typography>
              <Typography variant="body1">
                From time to time, FaircentPro will also send you marketing material. If you do not wish to receive such material, you may unsubscribe from our mailing list at any time by using the
                unsubscribe function in the electronic marketing communication or by contacting at support@faircentpro.com
              </Typography>
              <Typography variant="body1">
                You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.
              </Typography>
              <Typography variant="h3">21. Site-Provided Email and Postings</Typography>
              <Typography variant="body1">
                The Site may provide End Users with the ability to send email messages to other End Users and non-End Users and to post messages on the Site. FaircentPro is under no obligation to
                review any messages; information or content ("Postings") posted on the Site by End Users and assumes no responsibility or liability relating to any such Postings.
              </Typography>
              <Typography variant="body1">You understand and agree not to use any functionality provided by the Site to post content or initiate communications that contain:</Typography>
              <div className="orderlists">
                <ol type="i">
                  <li>
                    Any unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, profane, hateful, racially, ethnically or otherwise objectionable material of any kind,
                    including, but not limited to, any material which encourages conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any applicable
                    local, state, national or international law.
                  </li>
                  <li>Advertisements or solicitations of any kind.</li>
                  <li>Impersonate others or provide any kind of false information.</li>
                  <li>Personal information such as messages which state phone numbers, social security numbers, account numbers, addresses, or employer references.</li>
                  <li>
                    Messages by non-spokesperson employees of FaircentPro purporting to speak on behalf of FaircentPro or containing confidential information or expressing opinions concerning
                    FaircentPro.
                  </li>
                  <li>Messages that offer unauthorized downloads of any copyrighted or private information.</li>
                  <li>Chain letters of any kind.</li>
                  <li>
                    Identical (or substantially similar) messages to multiple recipients advertising any product or service, expressing a political or other similar message, or any other type of
                    unsolicited commercial message.
                  </li>
                </ol>
              </div>
              <Typography variant="body1">This prohibition includes but is not limited to:</Typography>
              <div className="orderlists">
                <ol type="a">
                  <li>Using Faircentpro.com invitations to send messages to people who don’t know you or who are unlikely to recognize you as a known contact;</li>
                  <li>Using Faircentpro.com to connect to people who don’t know you and then sending unsolicited promotional messages to those direct connections without their permission; and</li>
                  <li>Sending messages to distribution lists, newsgroup aliases, or group aliases.</li>
                </ol>
              </div>
              <Typography variant="h3">22. The Opt-out Practice</Typography>
              <div className="orderlists">
                <ol type="a">
                  <li>
                    If you are no longer interested in receiving e-mail announcements and other marketing information from us, or you want us to remove any personal information that we have collected
                    about you, you can opt-out anytime by sending an e-mail about your request to support@Faircentpro.com
                  </li>
                  <li>
                    We use third-party service providers to serve ads on our behalf across the Internet and sometimes on this site. They may collect anonymous information about your visits to our
                    website, and your interaction with our products and services. They may also use information about your visits to this and other websites to target advertisements for goods and
                    services. This anonymous information is collected through the use of a pixel tag, which is industry standard technology used by most major websites.
                  </li>
                </ol>
              </div>
              <Typography variant="h3">23. Links</Typography>
              <Typography variant="body1">
                The Site or third parties may provide links to other World Wide Web sites or resources. Because FaircentPro has no control over such sites and resources, you acknowledge and agree that
                FaircentPro is not responsible for the availability of such external sites or resources, and does not endorse and is not responsible or liable for any content, advertising, products or
                other materials on or available from such sites or resources. You further acknowledge and agree that FaircentPro shall not be responsible or liable, directly or indirectly, for any
                damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such site or resource.
              </Typography>
              <Typography variant="h3">24. Access to Password Protected/Secure Areas</Typography>
              <Typography variant="body1">
                Access to and use of password protected and/or secure areas of the Site is restricted to authorized Users only. Unauthorized individuals attempting to access these areas of the Site
                may be subject to prosecution.
              </Typography>
              <Typography variant="h3">25. Acknowledgements</Typography>
              <ol type="I">
                <li>You acknowledge that the FaircentPro is not engaged in either grant of loan or borrowing any money from any member of the website.</li>
                <li>
                  You acknowledge that FaircentPro is simply managing this website and neither FaircentPro nor any of its subsidiary or affiliate has any interest in either grant of loan or in
                  borrowing money from a member registered on the website.
                </li>
                <li>
                  You acknowledge that the FaircentPro is not at all responsible for any claim or damage in case either the lender who has agreed to grant loan has not granted loan or the person who
                  has agreed to repay the loan do not repay the loan.
                </li>
                <li>You acknowledge that FaircentPro in no manner warrants or guarantees the performance of a service provider that is providing services through the website of FaircentPro.</li>
                <li>
                  You acknowledge that the FaircentPro in no manner warrants and guarantees that the grantor of loan or the recipient of loan has provided all the information on this website which is
                  true and correct including his address, phone numbers etc.
                </li>
                <li>
                  You acknowledge that it is your responsibility to verify the information about the person on the website and FaircentPro is in no manner liable if the information provided on this
                  website is untrue or incorrect.
                </li>
                <li>
                  You acknowledge that FaircentPro is in no manner responsible for any claim of money or damages in the event the lender fails to either grant loan or a person fails to repay the loan
                  or misrepresents about his financial status or commits a fraud or a cheating or any other such illegal act.
                </li>
                <li>You acknowledge that you have taken expert advice from a legal consultant or any other expert so required about your status to lend or borrow on the site.</li>
                <li>
                  You acknowledge that you are aware of all your rights, duties and applicability of various laws including not limited to Money Lending Act, Income Tax Act, or any other statute, or
                  law, which may govern the lending and borrowing of monies within your State of residence or from where you ordinarily conduct your business.
                </li>
              </ol>
              <Typography variant="h3">26.Disclaimer of Warranties</Typography>
              <Typography variant="body1">
                ALL DETAILS OF THE MEMBERS OF THIS FAIRCENTPRO WEBSITE AND THEIR FINANCIAL STATUS, INCLUDED IN OR AVAILABLE THROUGH THIS SITE ARE PROVIDED FOR USE WITHOUT WARRANTIES OF ANY KIND,
                EITHER EXPRESS OR IMPLIED, FAIRCENTPRO, ITS SHAREHOLDERS, ITS HOLDING COMPANY, ITS SUBSIDIARIES, AND ITS LICENSORS DO NOT WARRANT THAT THE INFORMATION OR OTHER MATERIAL WHICH IS PLACED
                ON THE WEBSITE BY ANY PERSON IS CORRECT INCLUDING HIS FINANCIAL STATUS OR CAPABILITY OR HAS NOT APPROACHED THE OTHER USER OF THIS WEBSITE WITH A CRIMINAL INTENT TO EITHER CHEAT OR
                MISAPPROPRIATE OR DENY ANY OTHER USER OF ITS LEGITIMATE CLAIMS. BY UPLOADING ANY CONTENT, REPRESENTATION OR STATEMENT OF YOUR FINANCIAL STATUS OR YOUR PROOF OF RESIDENCE YOU REPRESENT
                AND WARRANT THAT YOU HAVE THE LAWFUL RIGHT TO REPRODUCE AND DISTRIBUTE SUCH CONTENT AND THAT THE CONTENT COMPLIES WITH ALL LAWS AND IS TRUE AND AUTHENTIC AND IS NOT IN ANY EVENT PLACED
                ON THE WEBSITE TO DEFRAUD OTHER USERS OR CREATE A FALSE IMPRESSION IN THEIR MINDS THAT YOU HAVE A SOUND FINANCIAL STATUS OR ARE REASONABLY COMMITTED TO DELIVER THE REPAYMENTS ON TIME.
                YOU ALSO REPRESENT THAT YOU HAVE NOT EVER BEEN ACCUSED TO ANY FRAUD, MISREPRESENTATION OR DEFAULT IN REPAYMENT OF ANY CLAIMS OR MONIES TO ANY THIRD PARTY WHETHER A FINANCIAL
                INSTITUTION OR NOT. YOU ALSO REPRESENT THAT THERE ARE NO OUTSTANDING DISPUTES IN CONNECTION WITH YOUR MORAL TURPITUDE OR FINANCIAL STATUS. YOU HEREBY REPRESENT AND WARRANT TO FAIRCENT
                THAT YOU ARE LEGALLY COMPETENT TO EXECUTE, BE BOUND BY AND ADHERE TO TERMS AND CONDITIONS OF TERMS AND CONDITIONS OF FAIRCENTPRO. YOUR USE OF THIS SITE IS SOLELY AT YOUR RISK. YOU
                AGREE THAT FAIRCENTPRO SHALL HAVE NO RESPONSIBILITY FOR ANY DAMAGES SUFFERED BY YOU IN CONNECTION WITH THIS SITE OR ANY CONTENT CONTAINED THEREIN OR ANY DAMAGE SUFFERED IN THE EVENT OF
                DEFAULT OR MISREPRESENTATION BY ANY OTHER USER OF THS WEBSITE.
              </Typography>
              <Typography variant="h3">27. Limitation of Liability</Typography>
              <Typography variant="body1">
                UNDER NO CIRCUMSTANCES SHALL FAIRCENTPRO, ITS SUBSIDIARIES, EMPLOYEES, OFFICERS, AFFILIATES, DIRECTORS, AGENTS, SUPPLIERS, SHAREHOLDERS, NON EXECUTIVE DIRECTORS OR ITS LICENSORS
                INVOLVED IN CREATING, PRODUCING, TRANSMITTING OR DISTRIBUTING PRODUCTS OR SERVICES ON THIS SITE, GRANTING OR RECEIVING LOAN FROM A MEMBER ASSOCIATED WITH THE FAIRCENTPRO WEBSITE BE
                LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES THAT RESULT FROM EITHER MISREPRESENTATION, FRAUD, COERCION, BREACH OF TRUST, MISAPPROPRIATION,
                CHEATING OR FOR ANY OTHER SUCH REASON OF ITS END USERS OR OF THIRD PARTIES INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS AND LOSS OF GOODWILL OR LOSS OF INTEREST.
              </Typography>
              <Typography variant="h3">28. Monitoring</Typography>
              <Typography variant="body1">
                In case it comes to your knowledge that any member of this website has committed default or fraud or misrepresents his financial status than you may inform FaircentPro in writing and
                upon receipt of such information FaircentPro shall call upon such third party to explain the allegations made in your Notice and upon receipt of an explanation from such third party
                FaircentPro shall in its own discretion decide as to whether such a person should be prohibited from using the website.
              </Typography>
              <Typography variant="h3">29. Applicable Laws:</Typography>
              <Typography variant="body1">Your use of this Site and any Term and Conditions stated in this policy is subject to laws of India.</Typography>
              <Typography variant="h3">30. Jurisdiction</Typography>
              <Typography variant="body1">Courts of Gurugram will have exclusive jurisdiction of dealing with all disputes arising from use of this site.</Typography>
              <Typography variant="h3">31. Trademarks</Typography>
              <Typography variant="body1">
                The trademarks, logos and service marks ("Marks") displayed on the Site are the property of FaircentPro and other respective persons. End Users are prohibited from using any Marks for
                any purpose including, but not limited to use as meta-tags on other pages or sites on the World Wide Web without the written permission of FaircentPro or such third party which may own
                the Marks. All information and content including any software programs available on or through the Site ("Content") is protected by copyright. End Users are prohibited from modifying,
                copying, distributing, transmitting, displaying, publishing, selling, licensing, creating derivative works or using any Content available on or through the Site for commercial or
                public purposes.
              </Typography>
              <Typography variant="h3">32. Changes to Terms and Conditions</Typography>
              <Typography variant="body1">
                FaircentPro reserves the right to change these Terms and Conditions of its Service without informing you. You should check our Site for any updated Terms and Conditions.
              </Typography>
              <Typography variant="body1">33. Miscellaneous</Typography>
              <Typography variant="body1">
                If any of these conditions are deemed invalid, void, or for any reason unenforceable, the parties agree that the court should endeavor to give effect to the parties’ intentions as
                reflected in the provision, and the unenforceable condition shall be deemed severable and shall not affect the validity and enforceability of any remaining condition. From time to time
                FaircentPro may offer special promotional offers which may or may not apply to your FaircentPro account. To avail of such promotional offers, you agree to be bound by any additional
                terms and conditions for these special offers which are laid down by FaircentPro. This T&amp;C and the relationship between you and FaircentPro will be governed by the laws as
                applicable in India. Any dispute(s) arising out of this T&amp;C will be handled in the competent courts of Gurugram, India. The failure of FaircentPro to act with respect to a breach
                by you or others does not waive its right to act with respect to subsequent or similar breaches. FaircentPro does not guarantee it will take action against all breaches of this
                T&amp;C. Except as otherwise, expressly provided in this T&amp;C, there shall be no third-party beneficiaries to this T&amp;C. This T&amp;C constitutes the entire agreement between you
                and FaircentPro and governs your use of the Site, superseding any prior agreements between you and FaircentPro with respect to the Site.
              </Typography>
              <Typography variant="h3">34. Payment Methods</Typography>
              <Typography variant="body1">
                You can pay FaircentPro using –payments services deployed by FaircentPro from time to time. FaircentPro has also deployed a system of electronic payment of fees, the details of which
                are available at <br />
                www.faircentpro.com
                <br />
                All transactions are processed in INR
              </Typography>
              <Typography variant="body1">
                35 FaircentPro holds the complete right to de-activate any lender/borrower on its own accord if the lender/borrower is suspected or found to be from competition engaging or trying to
                engage in any malicious activity.
              </Typography>
              <Typography variant="body1">
                36. We have also introduced a system where the borrowers and lenders have the opportunity to electronically sign the Loan Agreement. Details about how to sign the Loan Agreement are
                available at www.faircentpro.com
              </Typography>
              <Typography variant="body1">
                37. For further questions related to lending or borrowing kindly visit our FAQ’s section located at <br />
                https://www.faircentpro.com/faircentpro-faq.
              </Typography>
              <Typography variant="body1">
                38. FaircentPro is not liable for failure to perform any of its obligations if such failure is as a result of Force Majeure Situation e.g. Acts of God (including fire, flood,
                earthquake, storm, hurricane or other natural disaster, epidemic), war, invasion, act of foreign enemies, hostilities (regardless of whether war is declared), civil war, rebellion,
                revolution, insurrection, military or usurped power or confiscation, terrorist activities, nationalization, government sanction, blockage, embargo, labor dispute, strike, lockout,
                robbery, theft of data or any interruption or any failure of electricity or server, system, computer, internet or telephone service, or any other act beyond the control of FaircentPro.
              </Typography>
              <div className="button-block">
                <Button variant="contained" color="primary" size="large" onClick={() => setOpened(true)}>
                  TELL ME MORE
                </Button>
              </div>
            </Grid>
            <Grid item md={3}>
              <Sidebar sidebar={AboutUs} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default TermsOfUse;
