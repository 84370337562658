import React, { useEffect } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import { HowItWorks } from "../utils/SidebarMenu";

function ContactSuccess(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <div className="successful-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={8}>
              <Typography variant="body1">Thank you for your interest in our products. A sales representative will contact you shortly.</Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default ContactSuccess;
