import React, { useEffect, useContext } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import { GetStarted } from "../utils/SidebarMenu";
import { UserContext } from "../context/UserContext";

function DocumentsRequiredForKyc(props) {
  const { setOpened } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="Documents Required for KYC" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              <Typography variant="body1">Following documents and information are required to be submitted as KYC by interested NBFC’s</Typography>
              <div className="orderlists">
                <ol>
                  <li>Copy of NBFC Certificate of Registration issued by RBI </li>
                  <li>Copy of Pan Card of Company</li>
                  <li>Copy of MCA Registration</li>
                  <li>NBFC letterhead with logo for sanction letter template.</li>
                  <li>Names of Directors on the Board</li>
                  <li>List of Signatory Directors</li>
                  <li> Incorporation Date</li>
                  <li>NBFC-Faircent Agreement Signed Online</li>
                  <li>Grievance Redressal Procedure</li>
                  <li> Contact Details of NBFC SPOC</li>
                  <li> NBFC Bank Account Details</li>
                </ol>
              </div>
              <div className="button-block">
                <Button variant="contained" color="primary" size="large" onClick={() => setOpened(true)}>
                  TELL ME MORE
                </Button>
              </div>
            </Grid>
            <Grid item md={3}>
              <Sidebar sidebar={GetStarted} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default DocumentsRequiredForKyc;
