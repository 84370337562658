import React, { useEffect, useContext } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import { HowItWorks } from "../utils/SidebarMenu";
import { UserContext } from "../context/UserContext";

function LenderEscrowAccount(props) {
  const { setOpened } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="FaircentPro Lender Escrow Account" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              <Typography variant="body1">
                Lenders need to transfer the amount (which shall not be less than the minimum first recharge amount) they wish to invest through FaircentPro into their Escrow account. Once the loan is
                ready for disbursement, the funds are transferred from the lenders escrow account to the borrower’s bank account.
              </Typography>
              <Typography variant="body1">
                All transactions are through a Virtual Escrow Account that is managed by a SEBI registered Trustee. The Lender Escrow account can be funded or recharged using the following methods:
              </Typography>
              <div className="unorderlists">
                <ul>
                  <li>NEFT</li>
                  <li>RTGS/IMPS</li>
                  <li>UPI</li>
                  <li>Net Banking</li>
                  <li>Cheque</li>
                </ul>
              </div>
              <Typography variant="body1">
                A lender can withdraw funds from his escrow account by simply clicking on the Withdraw Money option on his dashboard and the money will be transferred to his bank account within 24
                banking hours.
              </Typography>
              <Typography variant="body1">
                FaircentPro has created a secure and seamless fund flow solution in partnership with ICICI Bank. The entire process is strongly monitored with multiple reconciliation points. All
                transactions are visible transparently in the escrow passbook available on the lender’s personalized dashboard.
              </Typography>
              <div className="button-block">
                <Button variant="contained" color="primary" size="large" onClick={() => setOpened(true)}>
                  TELL ME MORE
                </Button>
              </div>
            </Grid>
            <Grid item md={3}>
              <Sidebar sidebar={HowItWorks} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default LenderEscrowAccount;
