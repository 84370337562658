import React, { useEffect, useContext } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import PageBanner from "../components/PageBanner";
import Sidebar from "../components/Sidebar";
import { HowItWorks } from "../utils/SidebarMenu";
import { UserContext } from "../context/UserContext";

function WhatIsFaircentPro(props) {
  const { setOpened } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="What is FaircentPro" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              <Typography variant="body1">
                FaircentPro is a virtual platform which allows NBFCs &amp; Institutional lenders to seamlessly lend money to pre-qualified borrowers basis their choice of parameters simply, quickly
                and at lower costs.
              </Typography>
              <Typography variant="body1">
                Institutional lenders often face constraints or incur huge costs to access, assess, manage and monitor loans disbursed by them. High level of resources and efforts are spent in credit
                appraisal and due diligence of borrowers before loan disbursement and further to monitor loans post-disbursement. This leads to inflated cost, fewer disbursals, high risk and lower
                recovery.
              </Typography>
              <Typography variant="body1">
                Our mission is to provide NBFC’s &amp; Institutional lenders a unique technology solution that reduces the cost, effort and resources spent in loan management such that the lenders can
                focus on building their loan portfolio to earn higher returns.
              </Typography>
              <div className="button-block">
                <Button variant="contained" color="primary" size="large" onClick={() => setOpened(true)}>
                  TELL ME MORE
                </Button>
              </div>
            </Grid>
            <Grid item md={3}>
              <Sidebar sidebar={HowItWorks} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default WhatIsFaircentPro;
