import React, { useState, useEffect } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import { HowItWorks } from "../utils/SidebarMenu";
import TellMeMore from "../components/TellMeMore";

function CollectionRecovery(props) {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="Collections &amp; Recovery" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              <Typography variant="body1">
                FaircentPro offers a comprehensive Collection Management Service. Numerous steps are taken to ensure smooth and timely collection of repayments from borrowers, every month.
              </Typography>
              <div className="orderlists">
                <ol type="A">
                  <li>The loan is disbursed only after the borrower signs the legally bound loan agreement and security cheques/PDCs have been collected.</li>
                  <li>
                    The borrower’s repayments are collected through e/NACH mandate. e-mandate allows borrowers to pay EMIs online via Electronic Money Transfer and Auto-Debit facilities. Borrower will
                    click on the verification link sent through SMS/email and verify details using either net banking or debit card. The whole process can be easily completed within few minutes. Once
                    the mandate is approved, all future EMIs will be directly debited from borrower’s bank account.
                  </li>
                  <li>If e-mandate is not successful or the borrower’s bank is not covered under the NPCI for e-Mandate registration, then security PDC and NACH mandate collection is done.</li>
                  <li>Auto reminders, through SMS, WhatsApp messages, Emails and Automated Calls are sent to all borrowers 3-5 days before the EMI due date.</li>
                </ol>
              </div>
              <Typography variant="body1">
                However, even after best efforts to manage risk at every step, defaults can happen. A scientific approach has been adopted towards managing recovery services embedded with early
                warning systems to change strategy when required. Defaults are categorized into buckets depending on DPD and different strategy is adopted for each category.
              </Typography>
              <Typography variant="body1">In case of default, a 5-step recovery process to collect pending payments on behalf of Lenders is followed:</Typography>
              <div className="unorderlists">
                <ol type="1">
                  <li>In-house collection team establishes communication with defaulting borrower and follows-up for pending payments (Call/SMS/Email/Reference contact)</li>
                  <li>Legally compliant pan-India network of collection agencies augments physical recovery.</li>
                  <li>Legal notice is sent on behalf of the lenders.</li>
                  <li>In-house legal team supports escalation and drives arbitration process with borrowers to prevent legal cases.</li>
                  <li>Legal case is filed against the borrower on behalf of the lenders.</li>
                </ol>
              </div>
              <Typography variant="body1">
                All collection and recovery related activity undertaken on defaults, if any, is updated on lender’s dashboard and updated regularly with last action taken. However, collection and
                recovery management services are on best-effort basis which may not result in recovery and hence the risk of loss of both principal and interest exists.
              </Typography>
              <div className="button-block">
                <Button variant="contained" color="primary" size="large" onClick={() => setOpened(true)}>
                  TELL ME MORE
                </Button>
              </div>
            </Grid>
            <Grid item md={3}>
              <Sidebar sidebar={HowItWorks} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
      <TellMeMore opened={opened} setOpened={setOpened} />
    </React.Fragment>
  );
}

export default CollectionRecovery;
