import React, { useEffect, useContext } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import { UserContext } from "../context/UserContext";

function Pricing(props) {
  const { setOpened } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="Pricing" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={12}>
              <Typography variant="body1">
                <strong>Registration Fees</strong>
              </Typography>
              <Typography variant="body1">A non-refundable, one-time registration fee of Rs. 3,00,000/- is payable by the lender to FaircentPro at the time of Sign Up.</Typography>
              <Typography variant="body1">
                <strong>Transaction Fees</strong>
              </Typography>
              <Typography variant="body1">A non-refundable 5% transaction fee is charged on the loan amount disbursed.</Typography>
              <div className="button-block">
                <Button variant="contained" color="primary" size="large" onClick={() => setOpened(true)}>
                  TELL ME MORE
                </Button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Pricing;
