import React, { useEffect, useContext } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import { HowItWorks } from "../utils/SidebarMenu";
import { UserContext } from "../context/UserContext";

function CreditAppraisal(props) {
  const { setOpened } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="Credit Appraisal of borrowers" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              <Typography variant="body1">
                The credit appraisal algorithm uses traditional and non-traditional financial, social, credit and personal data to evaluate the borrower’s credit, financial and banking behavior and
                history.
              </Typography>
              <Typography variant="body1">
                <strong>FaircentPro Rule Engine</strong> is a tried and tested in-house algorithm, which assigns different weightages to factors such as a borrower’s ability, stability and intention
                to service a loan, to compute a score that helps determine the interest rate, loan tenure and amount at which the borrower’s loan request can be listed on the platform.
              </Typography>
              <Typography variant="body1">
                Before listing, borrowers are verified using a state of the art, contactless e-verification system that uses geo-tagging, Aadhar based validation, video interviews and face matching
                technology. FaircentPro is flexible and capable to undertake onsite/remote/digital verification of borrowers as required.
              </Typography>
              <Typography variant="body1">
                Borrower data will be transparently visible on the platform such that lenders can login to their dashboard and view it. The information will include a score generated basis FaircentPro
                assessment and qualitative remarks summarizing strengths and weaknesses of the borrower.
              </Typography>
              <Typography variant="body1">FaircentPro offers a process which can be customized to closely match a lender’s credit policy. The following options are available to lenders:</Typography>
              <div className="orderlists">
                <ol type="A">
                  <li>The KYC data of the borrower can be sent to the lender via an API integration once the lender sanctions the loan to the borrower.</li>
                  <li>In the event the lender requires that the KYC be done via its domain, routing of the KYC process via the lender’s domain can be done through API integration.</li>
                </ol>
              </div>
              <div className="button-block">
                <Button variant="contained" color="primary" size="large" onClick={() => setOpened(true)}>
                  TELL ME MORE
                </Button>
              </div>
            </Grid>
            <Grid item md={3}>
              <Sidebar sidebar={HowItWorks} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default CreditAppraisal;
