import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";

function NoMatch(props) {
  return (
    <React.Fragment>
      <Header />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              <Typography variant="h3">Page Not Found</Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default NoMatch;
