// How It Works
export const HowItWorks = [
  { id: 1, name: "What is FaircentPro", link: "what-is-faircentPro" },
  {
    id: 2,
    name: "Services Provided by FaircentPro",
    link: "services-provided",
  },
  { id: 3, name: "Loan Origination", link: "loan-origination" },
  { id: 4, name: "Credit Appraisal", link: "credit-appraisal" },
  {
    id: 5,
    name: "Disbursement & Signing Agreement",
    link: "disbursement-&-signing-agreement",
  },
  {
    id: 6,
    name: "FaircentPro Lender Escrow Account",
    link: "lender-escrow-account",
  },

  { id: 7, name: "Repayments", link: "repayments" },
  {
    id: 8,
    name: "Escrow Recharge & Other Fees",
    link: "escrow-recharge-&-other-fees",
  },
  { id: 9, name: "Collection & Recovery", link: "collection-&-recovery" },
  { id: 10, name: "Reports & Analytics", link: "reports-&-analytics" },
  {
    id: 11,
    name: "What FaircentPro does not do",
    link: "what-faircentpro-does-not-do",
  },
];

// How to Get Started Sidebar
export const GetStarted = [
  {
    id: 1,
    name: "Who can lend on FaircentPro",
    link: "who-can-lend-on-faircentpro",
  },
  { id: 2, name: "The Process", link: "/the-process" },
  {
    id: 3,
    name: "Get in touch to get started",
    link: "/get-in-touch-to-get-started",
  },
  {
    id: 4,
    name: "Documents Required for KYC",
    link: "/documents-required-for-kyc",
  },
];

// About Sidebar
export const AboutUs = [
  { id: 1, name: "The Team", link: "/the-team" },
  { id: 2, name: "FaircentPro Tech Stack", link: "/faircentpro-tech-stack" },
  { id: 2, name: "Our Lending Partners", link: "/our-lending-partners" },
  { id: 3, name: "Privacy Policy", link: "/privacy-policy" },
  { id: 4, name: "Terms of Use", link: "/terms-of-use" },
];
