import React, { useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
import TellMeMore from "./TellMeMore";

function Banner(props) {
  const [opened, setOpened] = useState(false);

  return (
    <React.Fragment>
      <div className="banner">
        <div className="home_wrapper">
          <Container>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={12}>
                <div className="content-slider">
                  <img src="../../assets/images/banner-content.png" className="img-responsive" alt="" onClick={() => setOpened(true)} />
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className="banner-video">
          <video playsInline className="video_background" autoPlay loop muted volume="0">
            <source src="../../assets/images/video.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="introduction">
          <Container>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={12}>
                <div className="introduction-inner">
                  <Typography variant="h2">
                    FaircentPro is a <strong>co-lending platform</strong> which offers NBFCs &amp; Institutional lenders a cutting edge technology solution which allows them to seamlessly co-lend
                    money to borrowers pre-qualified and selected based on their choice of parameters - <strong>simply, quickly</strong> and at <strong>lower costs.</strong>
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <TellMeMore opened={opened} setOpened={setOpened} />
      </div>
      <div className="co-lending-platform">
        <img src="../../assets/images/banner-content-mobile.png" className="img-responsive" alt="" onClick={() => setOpened(true)} />
      </div>
    </React.Fragment>
  );
}

export default Banner;
