import React, { useEffect, useContext } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import { AboutUs } from "../utils/SidebarMenu";
import { UserContext } from "../context/UserContext";

function TechStack(props) {
  const { setOpened } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="FaircentPro Tech Stack" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              <div className="full-image">
                <img src="../../assets/images/techstack.png" alt="" />
              </div>
              <Typography variant="body1">
                FaircentPro offers a tried and tested tech stack that drives innovation, size and scale. The platform is licensed from Faircent.com which is the largest P2P lending company in India
                and has serviced millions of transactions.
              </Typography>
              <Typography variant="body1">
                FaircentPro is based on a multi-tenant, multi-tier, multi-threaded architecture that supports scalability and an open API, friendly for deeper integration with partners and platforms
                on requirement-basis. All processes across lender and borrower journey from onboarding to recovery are fully automated, seamless and contactless. The platform provides the
                NBFC/institutional lender with user access controls to ensure right dissemination of information and seamless coordination. Maker and Checker roles are built into the admin login
                giving all key decision makers access to the platform.
              </Typography>
              <Typography variant="body1">
                The platform is 100% cloud based, with Amazon Web Services and Microsoft Azure for the best reliability and has received the ISO 27001 and CISA certification as it follows the industry
                best practices on cyber security and IT processes.
              </Typography>
              <div className="button-block">
                <Button variant="contained" color="primary" size="large" onClick={() => setOpened(true)}>
                  TELL ME MORE
                </Button>
              </div>
            </Grid>
            <Grid item md={3}>
              <Sidebar sidebar={AboutUs} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default TechStack;
