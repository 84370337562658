import React, { useEffect, useContext } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import { HowItWorks } from "../utils/SidebarMenu";
import { UserContext } from "../context/UserContext";

function ReportsAnalytics(props) {
  const { setOpened } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="Reporting &amp; Analytics" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              <Typography variant="body1">
                FaircentPro offers a dashboard with comprehensive Loan Portfolio Management System to enable quick decision making and keep lenders constantly updated. Lenders will have access to a
                personalized dashboard which they can access through unique login ID and password.
              </Typography>
              <Typography variant="body1">
                The dashboard will provide summary as well as detailed view of loan listings, borrower details, lender’s portfolio, transactional information and passbook details. The reports &amp;
                analytics section will cover more than 20 reports providing comprehensive visibility on data regarding disbursements, repayment, collections and other transactions.
              </Typography>
              <Typography variant="body1">
                The section has been designed to help lenders to monitor, manage and optimize a portfolio of loans. Periodic Analytics Reports on overall lending and performance trends on the platform
                will be shared to help lenders create and fine tune their lending strategy.
              </Typography>
              <div className="button-block">
                <Button variant="contained" color="primary" size="large" onClick={() => setOpened(true)}>
                  TELL ME MORE
                </Button>
              </div>
            </Grid>
            <Grid item md={3}>
              <Sidebar sidebar={HowItWorks} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default ReportsAnalytics;
