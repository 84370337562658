import React, { useEffect, useContext } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import { HowItWorks } from "../utils/SidebarMenu";
import { UserContext } from "../context/UserContext";

function ServicesProvided(props) {
  const { setOpened } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="Services Provided by FaircentPro" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              <Typography variant="body1">
                FaircentPro is a virtual platform which offers NBFCs and institutional lenders a fintech solution to lend money to pre-qualified credit worthy borrowers acquired through multiple
                channels and accessible through multiple devices – Android, IOS, Desktop.
              </Typography>
              <Typography variant="body1">
                Each NBFC/institutional lender will have its own unique dashboard, accessible through an admin login. The admin can create maker and checker roles for the login such that the dashboard
                can be accessed through multiple logins by all key decision makers.
              </Typography>
              <Typography variant="body1">
                On the FaircentPro platform, registered lenders will be able to see a list of pre-verified borrowers vetted by a credit appraisal algorithm deeply integrated with traditional and
                non-traditional financial, social, credit and personal data sources including all the credit bureaus.
              </Typography>
              <Typography variant="body1">
                Platform will undertake physical and video verification of the borrowers as well as on-site and/or remote KYC verification. Such transparently available data for each borrower can be
                used by the NBFC/institutional lender to take its own independent credit decisions. NBFCs/institutional lenders may also use their own modelling and underwriting mechanisms for taking
                the decision to lend to a borrower listed on the platform.
              </Typography>
              <Typography variant="body1">
                Further, services such as signing of an online and legally binding loan agreement with borrower, safe and secure financial transactions through trustee managed escrow account, detailed
                reporting and analytics on portfolio performance, managing collection and systematic recovery process will be availed.
              </Typography>
              <Typography variant="body1">
                FaircentPro is an integrated closed loop lending eco-system which offers total control and visibility on funding, disbursements, reporting, collections and recovery of loans originated
                on the platform.
              </Typography>
              <div className="button-block">
                <Button variant="contained" color="primary" size="large" onClick={() => setOpened(true)}>
                  TELL ME MORE
                </Button>
              </div>
            </Grid>
            <Grid item md={3}>
              <Sidebar sidebar={HowItWorks} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default ServicesProvided;
