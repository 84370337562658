import React, { useState, useEffect, useContext } from "react";
import { Container, Grid, Typography, Paper, Button } from "@mui/material";
import Header from "./../components/Header";
import Banner from "./../components/Banner";
import Footer from "./../components/Footer";
import TellMeMore from "../components/TellMeMore";
import { UserContext } from "../context/UserContext";

export default function Home(props) {
  const { opened, setOpened } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home-page">
      <Header />
      <Banner />
      <div className="platform-offer">
        <Container>
          <Grid container spacing={0}>
            <Grid item md={12}>
              <div className="platform-block-inner">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className="main-heading">
                      <Typography variant="h2">The FaircentPro Neo Lending Platform offers you:</Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={3} justify="center">
                  <Grid item xs={12} sm={6} md={4}>
                    <Paper elevation={2} className="offer-lists">
                      <div className="icon">
                        <img src="../../assets/images/icons/lower_cost.png" alt="" />
                      </div>
                      <div className="content">
                        <h4>Lower Costs</h4>
                        <p>Online, fully automated, tech platform ensures significant reduction in costs</p>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Paper elevation={2} className="offer-lists">
                      <div className="icon">
                        <img src="../../assets/images/icons/technology.png" alt="" />
                      </div>
                      <div className="content">
                        <h4>Tech Stack</h4>
                        <p>Technology that drives innovation, scale and efficiency</p>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Paper elevation={2} className="offer-lists">
                      <div className="icon">
                        <img src="../../assets/images/icons/credit_appraisal.png" alt="" />
                      </div>
                      <div className="content">
                        <h4>Credit Appraisal</h4>
                        <p>Customizable process that leverages new-age data science</p>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Paper elevation={2} className="offer-lists">
                      <div className="icon">
                        <img src="../../assets/images/icons/operations.png" alt="" />
                      </div>
                      <div className="content">
                        <h4>Operational Management</h4>
                        <p>Strong KYC, verification, agreement signing and loan disbursement processes</p>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Paper elevation={2} className="offer-lists">
                      <div className="icon">
                        <img src="../../assets/images/icons/report_analytics.png" alt="" />
                      </div>
                      <div className="content">
                        <h4>Reporting &amp; Analytics</h4>
                        <p>Comprehensive portfolio monitoring, management and recalibration</p>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Paper elevation={2} className="offer-lists">
                      <div className="icon">
                        <img src="../../assets/images/icons/collection_recovery.png" alt="" />
                      </div>
                      <div className="content">
                        <h4>Collection &amp; Recovery</h4>
                        <p>Proven and Automated, end to end Collections and Recovery Processes backed by legal services</p>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Paper elevation={2} className="offer-lists">
                      <div className="icon">
                        <img src="../../assets/images/icons/borrowers.png" alt="" />
                      </div>
                      <div className="content">
                        <h4>Borrower Sourcing &amp; Origination</h4>
                        <p>Multi-channel, multi-type, pan India sourcing of borrowers</p>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Paper elevation={2} className="offer-lists">
                      <div className="icon">
                        <img src="../../assets/images/icons/funds_flow.png" alt="" />
                      </div>
                      <div className="content">
                        <h4>Fund Flow Mechanisms</h4>
                        <p>Secure fund flow transactions under third-party trusteeship supported by multiple payment channels</p>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <div className="lending-cost">
                      <div className="table-title">
                        <Typography variant="h3">How does Faircent Pro enable you to lower your lending costs:</Typography>
                      </div>
                      <div className="table-data">
                        <Paper elevation={2} className="table-data-lists">
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                              <div className="table-item first-item">
                                <div className="table-icon">
                                  <img src="../../assets/images/icons/table_icon1.png" alt="" />
                                </div>
                                <span className="table-caption">Borrower Origination and onboarding costs</span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <div className="table-item second-item">
                                <span className="est-cost">Estimated Cost As % of Disbursement</span>
                                <span className="disburse">1 to 2%</span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <div className="table-item second-item">
                                <span className="est-cost">Cost with Faircent Pro</span>
                                <span className="included">
                                  <img src="../../assets/images/icons/included.png" alt="" />
                                  Included
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </Paper>
                        <Paper elevation={2} className="table-data-lists">
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                              <div className="table-item first-item">
                                <div className="table-icon">
                                  <img src="../../assets/images/icons/table_icon3.png" alt="" />
                                </div>
                                <span className="table-caption">Borrower Verification &amp; KYC costs</span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <div className="table-item second-item">
                                <span className="est-cost">Estimated Cost As % of Disbursement</span>
                                <span className="disburse">1 to 2%</span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <div className="table-item second-item">
                                <span className="est-cost">Cost with Faircent Pro</span>
                                <span className="included">
                                  <img src="../../assets/images/icons/included.png" alt="" />
                                  Included
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </Paper>
                        <Paper elevation={2} className="table-data-lists">
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                              <div className="table-item first-item">
                                <div className="table-icon">
                                  <img src="../../assets/images/icons/table_icon2.png" alt="" />
                                </div>
                                <span className="table-caption">Credit Appraisal and Loan offer generation costs</span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <div className="table-item second-item">
                                <span className="est-cost">Estimated Cost As % of Disbursement</span>
                                <span className="disburse">1 to 2%</span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <div className="table-item second-item">
                                <span className="est-cost">Cost with Faircent Pro</span>
                                <span className="included">
                                  <img src="../../assets/images/icons/included.png" alt="" />
                                  Included
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </Paper>

                        <Paper elevation={2} className="table-data-lists">
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                              <div className="table-item first-item">
                                <div className="table-icon">
                                  <img src="../../assets/images/icons/table_icon4.png" alt="" />
                                </div>
                                <span className="table-caption">Collection, Recovery &amp; Legal costs</span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <div className="table-item second-item">
                                <span className="est-cost">Estimated Cost As % of Disbursement</span>
                                <span className="disburse">1 to 2%</span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <div className="table-item second-item">
                                <span className="est-cost">Cost with Faircent Pro</span>
                                <span className="included">
                                  <img src="../../assets/images/icons/included.png" alt="" />
                                  Included
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </Paper>
                        <Paper elevation={2} className="table-data-lists">
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                              <div className="table-item first-item">
                                <div className="table-icon">
                                  <img src="../../assets/images/icons/table_icon5.png" alt="" />
                                </div>
                                <span className="table-caption">Technology, IT Systems, Reporting &amp; Analytics costs</span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <div className="table-item second-item">
                                <span className="est-cost">Estimated Cost As % of Disbursement</span>
                                <span className="disburse">1 to 2%</span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <div className="table-item second-item">
                                <span className="est-cost">Cost with Faircent Pro</span>
                                <span className="included">
                                  <img src="../../assets/images/icons/included.png" alt="" />
                                  Included
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </Paper>
                        <Paper elevation={2} className="table-data-lists">
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                              <div className="table-item first-item">
                                <span className="table-caption">Total Cost</span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <div className="table-item second-item">
                                <span className="est-cost">Estimated Cost As % of Disbursement</span>
                                <span className="disburse">8 to 9%</span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <div className="table-item second-item">
                                <span className="est-cost">Cost with Faircent Pro</span>
                                <span className="included">Upto 3-5% of disbursement</span>
                              </div>
                            </Grid>
                          </Grid>
                        </Paper>
                      </div>
                      <div className="table-total-data">
                        <Typography variant="h3">Cost Savings: upto ~5% of disbursement</Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <div className="tell-me-more">
                    <Button variant="contained" size="large" color="primary" onClick={() => setOpened(true)}>
                      Tell Me More
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="building-blocks">
        <Container>
          <Grid container spacing={0}>
            <Grid item md={12}>
              <div className="building-block-inner">
                <div className="main-heading">
                  <Typography variant="h2">The Faircent Pro Neo Lending Platform Building Blocks</Typography>
                </div>
                <div className="block-cards">
                  <div className="card-heading">
                    {/* <div className="heading-icon">
                      <span>
                        <img
                          src="../assets/images/icons/tech-stack.png"
                          alt=""
                        />
                      </span>
                    </div> */}
                    <div className="heading-title">
                      <Typography variant="h3">Tech-Stack</Typography>
                    </div>
                    <div className="heading-caption">
                      <Typography variant="h4">Technology that drives innovation, scale and efficiency.</Typography>
                    </div>
                  </div>
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Millions of transactions</h4>
                          <p>Tried and Tested Tech Stack that can seamlessly support millions of transactions</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Fully Automated</h4>
                          <p>Online and contactless lender &amp; borrower journeyes</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Open API Collaborations</h4>
                          <p>API friendly open stack for deeper partner integrations that ensure availability of a wide range of products &amp; services</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Cloud Computing</h4>
                          <p>100% on the cloud with Amazon Web Services and Microsoft Azure for the best reliability and security of transactions</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Certified Quality Promise</h4>
                          <p>ISO 27001 and CISA certified following the industry best practices on cyber security and IT processes</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Scalable Tech Architecture</h4>
                          <p>Scalable Multi-tenant, Multi-tier, Multi-threaded platform to help us grow together</p>
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div className="tell-me-more">
                        <Button variant="contained" size="large" color="primary" onClick={() => setOpened(true)}>
                          Tell Me More
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="block-cards">
                  <div className="card-heading">
                    {/* <div className="heading-icon">
                      <span>
                        <img
                          src="../assets/images/icons/credit-appraisal.png"
                          alt=""
                        />
                      </span>
                    </div> */}
                    <div className="heading-title">
                      <Typography variant="h3">Credit Appraisal</Typography>
                    </div>
                    <div className="heading-caption">
                      <Typography variant="h4">Customizable process that leverages new-age data sciences</Typography>
                    </div>
                  </div>
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Customisable Credit Policy</h4>
                          <p>Can be made to closely match your credit policy</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Uses Traditional &amp; Non traditional data</h4>
                          <p>Banking, Income Tax, Employment, Device, Social, Transactional ++.</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>All 4 Credit Bureaus integrated</h4>
                          <p>TU CIBIL, Experian, Equifax, CRIF Highmark</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Both Quantitative and Qualitative feedback</h4>
                          <p>To supplement your assessment</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Traditional &amp; New-Age Data Science</h4>
                          <p>Banking, financial, personal, credit data evaluation along with social, device and transaction behavior analysis+</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Tried &amp; Tested Credit Algorithm</h4>
                          <p>AI and Machine learning based pricing engine to derive optimum loan offer</p>
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div className="tell-me-more">
                        <Button variant="contained" size="large" color="primary" onClick={() => setOpened(true)}>
                          Tell Me More
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="block-cards">
                  <div className="card-heading">
                    {/* <div className="heading-icon">
                      <span>
                        <img
                          src="../assets/images/icons/operations-icon.png"
                          alt=""
                        />
                      </span>
                    </div> */}
                    <div className="heading-title">
                      <Typography variant="h3">Operational Management</Typography>
                    </div>
                    <div className="heading-caption">
                      <Typography variant="h4">Strong KYC, verification, agreement signing and loan disbursement</Typography>
                    </div>
                  </div>
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Digital KYC &amp; Verification</h4>
                          <p>Onsite and Online (video based)</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Lender Borrower Agreement</h4>
                          <p>Simple, Legally Compliant, Online signing</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Maker and Checker roles</h4>
                          <p>Multiple login giving access to all key decision makers</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Loan Sanction, Disbursement Letters</h4>
                          <p>In your format and on your letterhead</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Data Validation</h4>
                          <p>All documents validated through third party; automated bank statement parsing</p>
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div className="tell-me-more">
                        <Button variant="contained" size="large" color="primary" onClick={() => setOpened(true)}>
                          Tell Me More
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="block-cards">
                  <div className="card-heading">
                    {/* <div className="heading-icon">
                      <span>
                        <img
                          src="../assets/images/icons/reporting-analytics.png"
                          alt=""
                        />
                      </span>
                    </div> */}
                    <div className="heading-title">
                      <Typography variant="h3">Reporting &amp; Analytics</Typography>
                    </div>
                    <div className="heading-caption">
                      <Typography variant="h4">Comprehensive portfolio monitoring, management and recalibration</Typography>
                    </div>
                  </div>
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Personalised Dashboard</h4>
                          <p>To access loan data for quick and informed decision making</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Portfolio management</h4>
                          <p>Regular portfolio monitoring and reporting to optimize returns</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Periodic Reporting</h4>
                          <p>To help fine tune lender strategy basis macro trends</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Performance analytics</h4>
                          <p>Comprehensive visibility on lender’s investment, earning and collection through various reports updated real-time</p>
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div className="tell-me-more">
                        <Button variant="contained" size="large" color="primary" onClick={() => setOpened(true)}>
                          Tell Me More
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="block-cards">
                  <div className="card-heading">
                    {/* <div className="heading-icon">
                      <span>
                        <img
                          src="../assets/images/icons/collection-icon.png"
                          alt=""
                        />
                      </span>
                    </div> */}
                    <div className="heading-title">
                      <Typography variant="h3">Collections &amp; Recovery</Typography>
                    </div>
                    <div className="heading-caption">
                      <Typography variant="h4">Proven and Automated, end to end Collections and Recovery Processes backed by legal services</Typography>
                    </div>
                  </div>
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography variant="h4" className="service-caption">
                        Collections as a service
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Automated collection</h4>
                          <p>Setting up of e-NACH, e-Mandate for auto-debit of EMIs</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Multiple repayment mechanism</h4>
                          <p>NEFT, UPI, RTGS, IMPS, Payment Gateways, Wallets</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Mitigating risk of default</h4>
                          <p>Collection of Security Cheques and management of PDCs</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Multi-Channel Reminders</h4>
                          <p>Automated Calls, SMS, Email, WhatsApp reminders to borrowers before and after EMI due date</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography variant="h4" className="service-caption">
                        Recovery Services
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Efficient DPD Management</h4>
                          <p>Different strategies for different buckets of DPD </p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Analytics based Approach</h4>
                          <p>Early warning systems for proactive action and modifying recovery strategy</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Pan-India Recovery Network</h4>
                          <p>Third party network of recovery agencies from across India to augment physical recovery</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Legal Support</h4>
                          <p>In-House legal team for proper escalation and implementation of arbitration process</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Legal Action</h4>
                          <p>Filing of cases against defaulters under Section 138 and diligent follow-up</p>
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div className="tell-me-more">
                        <Button variant="contained" size="large" color="primary" onClick={() => setOpened(true)}>
                          Tell Me More
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="block-cards">
                  <div className="card-heading">
                    {/* <div className="heading-icon">
                      <span>
                        <img
                          src="../assets/images/icons/borrower-sourcing.png"
                          alt=""
                        />
                      </span>
                    </div> */}
                    <div className="heading-title">
                      <Typography variant="h3">Borrower Sourcing &amp; Origination</Typography>
                    </div>
                    <div className="heading-caption">
                      <Typography variant="h4">Multi-channel, multi-type, pan India sourcing of borrowers</Typography>
                    </div>
                  </div>
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Multi-Channel</h4>
                          <p>DSA, Organic, Digital Partners, Products ++</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Multi-Device</h4>
                          <p>Web, iOS, Android, Unified APIs</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Multiple Loan Types</h4>
                          <p>Unsecured, Secured, Consumer, Term, Business, Working Capital ++</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Variety of Tenures</h4>
                          <p>EMI based, Line of Credit, Daily, Bullet ++</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Pan India sourcing</h4>
                          <p>from 10k+ pin codes</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Superior Borrower Experience</h4>
                          <p>with sophisticated contactless LMS</p>
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div className="tell-me-more">
                        <Button variant="contained" size="large" color="primary" onClick={() => setOpened(true)}>
                          Tell Me More
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="block-cards">
                  <div className="card-heading">
                    {/* <div className="heading-icon">
                      <span>
                        <img
                          src="../assets/images/icons/funds-flow.png"
                          alt=""
                        />
                      </span>
                    </div> */}
                    <div className="heading-title">
                      <Typography variant="h3">Funds Flow Mechanisms</Typography>
                    </div>
                    <div className="heading-caption">
                      <Typography variant="h4">Secure fund flow transactions under third-party trusteeship supported by multiple payment channels</Typography>
                    </div>
                  </div>
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Innovative fund flow mechanism</h4>
                          <p>Based on learnings from P2P Lending &amp; created in partnership with ICICI Bank</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Secure Financial Transactions</h4>
                          <p>Through a Virtual Escrow account managed by a SEBI registered Trustee</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Multiple payment channels supported</h4>
                          <p>NEFT, UPI, RTGS, IMPS, Payment Gateways, Wallets</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Effective Monitoring</h4>
                          <p>Multiple Reconciliation endpoints with maker checker monitoring on all transactions</p>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={2} className="card-items">
                        <div>
                          <h4>Complete Transparency</h4>
                          <p>Visibility of all transactions in Escrow passbook on lender’s dashboard</p>
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div className="tell-me-more">
                        <Button variant="contained" size="large" color="primary" onClick={() => setOpened(true)}>
                          Tell Me More
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* <div className="institutional-lender">
        <Container>
          <Grid item md={12}>
            <div className="main-heading">
              <Typography variant="h2">
                Institutional Lenders on Board
              </Typography>
            </div>
          </Grid>
          <Grid container spacing={0}>
            <Grid item md={12}>
              <Paper elevation={2} className="board-card">
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <div className="board-lists">
                      <img src="../assets/images/board01.png" alt="" />
                    </div>
                  </Grid>
                  <Grid item md={3}>
                    <div className="board-lists">
                      <img src="../assets/images/board02.png" alt="" />
                    </div>
                  </Grid>
                  <Grid item md={3}>
                    <div className="board-lists">
                      <img src="../assets/images/board03.png" alt="" />
                    </div>
                  </Grid>
                  <Grid item md={3}>
                    <div className="board-lists">
                      <img src="../assets/images/board04.png" alt="" />
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div> */}

      <div
        className="lending-costs"
        style={{
          backgroundImage: `url('../assets/images/lending-cost-bg.jpg')`,
        }}
      >
        <Container>
          <Grid item md={12}>
            <div className="lending-cost-inner">
              <Typography variant="h2">
                Ready to reduce your <br />
                Lending Costs?
              </Typography>
              <div className="tell-me-more">
                <Button variant="contained" size="large" color="primary" onClick={() => setOpened(true)}>
                  Tell Me More
                </Button>
              </div>
            </div>
          </Grid>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
