export const state = [
  { id: 1, name: " Uttar pradesh" },
  { id: 2, name: " Haryana" },
  { id: 3, name: " Andhra Pradesh" },
  { id: 4, name: " Arunachal Pradesh" },
  { id: 5, name: " Andaman and Nicobar Islands" },
  { id: 6, name: " Assam" },
  { id: 7, name: " Bihar" },
  { id: 8, name: " Chandigarh" },
  { id: 9, name: " Chhattisgarh" },
  { id: 10, name: " Delhi" },
  { id: 11, name: " Goa" },
  { id: 12, name: " Gujarat" },
  { id: 13, name: " Haryana" },
  { id: 14, name: " Himachal Pradesh" },
  { id: 15, name: " Jammu & Kashmir" },
  { id: 16, name: " Jharkhand" },
  { id: 17, name: " Karnataka" },
  { id: 18, name: " Kerala" },
  { id: 19, name: " Madhya Pradesh" },
  { id: 20, name: " Maharashtra" },
  { id: 21, name: " Manipur" },
  { id: 22, name: " Meghalaya" },
  { id: 23, name: " Mizoram" },
  { id: 24, name: " Nagaland" },
  { id: 25, name: " Odisha" },
  { id: 26, name: " Punjab" },
  { id: 27, name: " Rajasthan" },
  { id: 28, name: " Sikkim" },
  { id: 29, name: " Tamil Nadu" },
  { id: 30, name: " Tripura" },
  { id: 31, name: " Uttaranchal" },
  { id: 32, name: " Uttar Pradesh" },
  { id: 33, name: " West Bengal" },
  { id: 34, name: " CHANDIGARH" },
  { id: 35, name: " CHATTISGARH" },
  { id: 36, name: " Dadra & Nagar Haveli" },
  { id: 37, name: " DAMAN & DIU" },
  { id: 38, name: " LAKSHADWEEP" },
  { id: 39, name: " TELANGANA" },
  { id: 41, name: " PONDICHERRY" },
  { id: 42, name: " DAMAN & DIU" },
  { id: 43, name: " DADRA & NAGAR HAVELI" },
  { id: 44, name: " JAMMU & KASHMIR" },
];
