import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import MenuBar from "./MenuBar";
import DrawerMenu from "./DrawerMenu";

function Header(props) {
  const [showDrawer, setShowDrawer] = useState(false);

  const handleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  return (
    <div className="page-header">
      <Container>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12}>
            <div className="header-inner">
              <div className="logo">
                <Link to="/">
                  <img src="../assets/images/logo.png" alt="Faircent Pro" />
                </Link>
              </div>
              <div className="navbar-list">
                <MenuBar />
              </div>
              <div className="hamburger-menu" onClick={handleDrawer}>
                {showDrawer ? <i className="fa fa-times"></i> : <i className="fa fa-bars"></i>}
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      <DrawerMenu showDrawer={showDrawer} handleDrawer={handleDrawer} />
    </div>
  );
}

export default Header;
