import React, { useEffect, useContext } from "react";
import { Button, Container, Grid, Typography, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import { HowItWorks } from "../utils/SidebarMenu";
import { UserContext } from "../context/UserContext";

function Repayments(props) {
  const { setOpened } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="Repayments" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              <Typography variant="body1">
                Borrower’s can repay the loan through equated monthly investments (EMI) calculated on reducing balance method (unless otherwise mention in the product profile). The EMIs are received
                from the borrower through e/Nach mandate (auto-debit) in a collection escrow account, where it is reconciled before transferring to the lender’s escrow account.
              </Typography>
              <Typography variant="body1">
                The Lender will receive EMIs on or before the 15th of every month. If a borrower fails to pay an EMI within a stipulated time, a penal interest of 18% p.a is levied on the borrower
                which is payable to the lender. Faircent will also charge a collection penalty per delayed payment as per the schedule of charges given below:
              </Typography>
              <div className="table-data">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Days delayed</TableCell>
                      <TableCell>Fee payable per delay</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>1-90 days</TableCell>
                      <TableCell>Rs 500</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>91-180 days</TableCell>
                      <TableCell>Rs 1000</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>181-365 days</TableCell>
                      <TableCell>Rs 2500</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <div className="button-block">
                <Button variant="contained" color="primary" size="large" onClick={() => setOpened(true)}>
                  TELL ME MORE
                </Button>
              </div>
            </Grid>
            <Grid item md={3}>
              <Sidebar sidebar={HowItWorks} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Repayments;
