import React, { useEffect, useContext } from "react";
import { Box, Button, Card, CardContent, Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import { AboutUs } from "../utils/SidebarMenu";
import { UserContext } from "../context/UserContext";

function OurLendingPartners(props) {
  const { setOpened } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="Our Lending Partners" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              <Card>
                <CardContent>
                  <img src="/assets/images/partners/dmi.jpg" width="200" alt="" />

                  <Typography variant="h6" gutterBottom>
                    <strong>Brand Name: DMI FINANCE</strong>
                  </Typography>

                  <Typography sx={{ fontSize: 14 }} gutterBottom>
                    Legal Name (Business Registered Name): DMI FINANCE PRIVATE LIMITED
                  </Typography>

                  <Typography>
                    Website Link:{" "}
                    <a href="https://dmifinance.in/" target="_blank">
                      https://dmifinance.in/
                    </a>
                  </Typography>

                  <Typography variant="body2">Grievance Officer details:</Typography>
                  <Box sx={{ padding: "5px 20px" }}>
                    <Typography variant="body2">1. Name: Mr. Ashish Sarin</Typography>
                    <Typography variant="body2">2. Designation: Senior Vice President - Customer Success</Typography>
                    <Typography variant="body2">
                      3. Time to operate: <strong>9:00 am to 8:00 pm</strong> from Monday to Saturday.
                    </Typography>
                    <Typography variant="body2">4. Email: head.services@dmifinance.in</Typography>
                    <Typography variant="body2">5. Address: Express Building, 3 rd Floor, 9-10, Bahadur Shah Zafar Marg, New Delhi- 110002</Typography>
                  </Box>
                </CardContent>
              </Card>

              <br />

              <Card>
                <CardContent>
                  <img src="/assets/images/partners/birla.jpg" width="200" alt="" />

                  <Typography variant="h6" gutterBottom>
                    <strong>Brand Name: ADITYA BIRLA FINANCE</strong>
                  </Typography>

                  <Typography sx={{ fontSize: 14 }} gutterBottom>
                    Legal Name (Business Registered Name): ADITYA BIRLA FINANCE LIMITED
                  </Typography>

                  <Typography>
                    Website Link:{" "}
                    <a href="https://finance.adityabirlacapital.com/" target="_blank">
                      https://finance.adityabirlacapital.com/
                    </a>
                  </Typography>

                  <Typography variant="body2">Grievance Officer details:</Typography>
                  <Box sx={{ padding: "5px 20px" }}>
                    <Typography variant="body2">1. Name: Mr. Arijit Sen</Typography>
                    <Typography variant="body2">2. Designation: Principal Nodal Officer</Typography>
                    <Typography variant="body2">3. Time to operate: 10:00AM – 7:00PM (Monday to Friday)</Typography>
                    <Typography variant="body2">4. Email: grievance.finance@adityabirlacapital.com</Typography>
                    <Typography variant="body2">5. Address: Aditya Birla Finance Limited,10th Floor, R-Tech Park, Nirlon Complex, Goregaon, Mumbai – 400063</Typography>
                  </Box>
                </CardContent>
              </Card>

              <br />

              <Card>
                <CardContent>
                  <img src="/assets/images/partners/incred.jpg" width="200" alt="" />

                  <Typography variant="h6" gutterBottom>
                    <strong>Brand Name: InCred Finance</strong>
                  </Typography>

                  <Typography sx={{ fontSize: 14 }} gutterBottom>
                    Legal Name (Business Registered Name): InCred Financial Services Limited
                  </Typography>

                  <Typography>
                    Website Link:{" "}
                    <a href="https://www.incred.com/home/" target="_blank">
                      https://www.incred.com/home/
                    </a>{" "}
                  </Typography>

                  <Typography variant="body2">Grievance Officer details:</Typography>
                  <Box sx={{ padding: "5px 20px" }}>
                    <Typography variant="body2">1. Name: Ms. Rosy Dsouza</Typography>
                    <Typography variant="body2">2. Designation: Customer Care Service</Typography>
                    <Typography variant="body2">3. Time to operate: 10:00AM – 7:00PM (Monday to Friday)</Typography>
                    <Typography variant="body2">4. Email: care@incred.com</Typography>
                    <Typography variant="body2">5. Address: Incred Financial Services Limited, 1203, 12th Floor, B Wing, The Capital, Bandra Kurla Complex, Mumbai - 400 051</Typography>
                  </Box>
                </CardContent>
              </Card>

              <br />

              <Card>
                <CardContent>
                  <img src="/assets/images/partners/lendingcart.jpg" width="200" alt="" />

                  <Typography variant="h6" gutterBottom>
                    <strong>Brand Name: Lendingkart</strong>
                  </Typography>

                  <Typography sx={{ fontSize: 14 }} gutterBottom>
                    Legal Name (Business Registered Name): Lendingkart Finance Limited
                  </Typography>

                  <Typography>
                    Website Link:{" "}
                    <a href="https://www.lendingkart.com/" target="_blank">
                      https://www.lendingkart.com/
                    </a>{" "}
                  </Typography>

                  <Typography variant="body2">Grievance Officer details:</Typography>
                  <Box sx={{ padding: "5px 20px" }}>
                    <Typography variant="body2">1. Name: Ms. Ajitha K</Typography>
                    <Typography variant="body2">2. Designation: Senior Director – Customer Service</Typography>
                    <Typography variant="body2">3. Time to operate: 10.30 am to 6.00 pm (Monday to Friday except National Holidays)</Typography>
                    <Typography variant="body2">4. Email: grievance.redressal@lendingkart.com</Typography>
                    <Typography variant="body2">5. Address: 4th Floor, 401, Iconic Shyamal, Nr. Shymal Cross road, Satellite, Ahmedabad, Gujarat, 380015</Typography>
                  </Box>
                </CardContent>
              </Card>

              <br />

              <br />

              <div className="button-block">
                <Button variant="contained" color="primary" size="large" onClick={() => setOpened(true)}>
                  TELL ME MORE
                </Button>
              </div>
            </Grid>
            <Grid item md={3}>
              <Sidebar sidebar={AboutUs} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default OurLendingPartners;
