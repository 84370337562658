import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import TellMeMore from "./TellMeMore";

function MenuBar(props) {
  const [opened, setOpened] = useState(false);

  return (
    <React.Fragment>
      <ul>
        <li>
          <a href="https://in.faircentpro.com/?utm_source=direct&utm_medium=direct&campaign_name=direct" target="_blank" activeClassName="active">
            Apply for Loan
          </a>
        </li>
        <li className="drop-down">
          <NavLink to="#!" activeClassName="active">
            How it works
          </NavLink>
          <ul>
            <li>
              <Link to="/what-is-faircentPro">What is FaircentPro</Link>
            </li>
            <li>
              <Link to="/services-provided">Services Provided by FaircentPro</Link>
            </li>
            <li>
              <Link to="/loan-origination">Loan Origination</Link>
            </li>
            <li>
              <Link to="/credit-appraisal">Credit Appraisal</Link>
            </li>
            <li>
              <Link to="/disbursement-&-signing-agreement">Disbursement &amp; Signing Agreement</Link>
            </li>
            <li>
              <Link to="/lender-escrow-account">FaircentPro Lender Escrow Account</Link>
            </li>
            <li>
              <Link to="/repayments">Repayments</Link>
            </li>
            <li>
              <Link to="/escrow-recharge-&-other-fees">Escrow Recharge &amp; Other Fees</Link>
            </li>
            <li>
              <Link to="/collection-&-recovery">Collection &amp; Recovery</Link>
            </li>
            <li>
              <Link to="/reports-&-analytics">Reports &amp; Analytics</Link>
            </li>
            <li>
              <Link to="/what-faircentpro-does-not-do">What FaircentPro does not do</Link>
            </li>
          </ul>
        </li>
        <li className="drop-down">
          <NavLink to="#!" activeClassName="active">
            How to get started?
          </NavLink>
          <ul>
            <li>
              <Link to="/who-can-lend-on-faircentpro">Who can lend on FaircentPro</Link>
            </li>
            <li>
              <Link to="/the-process">The Process</Link>
            </li>
            <li>
              <Link to="/get-in-touch-to-get-started">Get in touch to get started</Link>
            </li>
            <li>
              <Link to="/documents-required-for-kyc">Documents Required for KYC</Link>
            </li>
          </ul>
        </li>
        <li className="drop-down">
          <NavLink to="#!" activeClassName="active">
            About Us
          </NavLink>
          <ul>
            <li>
              <Link to="/the-team">The Team</Link>
            </li>
            <li>
              <Link to="/faircentpro-tech-stack">FaircentPro Tech Stack</Link>
            </li>
            <li>
              <Link to="/our-lending-partners">Our Lending Partners</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms-of-use">Terms of Use</Link>
            </li>
          </ul>
        </li>
        <li>
          <NavLink to="/pricing" activeClassName="active">
            Pricing
          </NavLink>
        </li>
        <li onClick={() => setOpened(true)}>
          <span>Contact Us</span>
        </li>
      </ul>
      <TellMeMore opened={opened} setOpened={setOpened} />
    </React.Fragment>
  );
}

export default MenuBar;
