import React, { useEffect, useContext } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import { HowItWorks } from "../utils/SidebarMenu";
import { UserContext } from "../context/UserContext";

function LoanOrigination(props) {
  const { setOpened } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="Loan Origination" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              <Typography variant="body1">
                Borrowers are sourced from various sources across India using multiple methods. FaircentPro provides a wide range of loan products i.e. – unsecured loans, secured loans, gold loans,
                line of credit etc. – with a variety of repayment options such as EMI-based, non-EMI based, daily credit etc.
              </Typography>
              <Typography variant="body1">Borrower acquisitions is supported via multiple channels such as:</Typography>
              <div className="unorderlists">
                <ul>
                  <li>Organic borrower traffic</li>
                  <li>Deep partnerships with aggregators</li>
                  <li>Affiliate marketers</li>
                  <li>Fintech Partners</li>
                  <li>Structured and pooled loan product offerings.</li>
                </ul>
              </div>
              <div className="button-block">
                <Button variant="contained" color="primary" size="large" onClick={() => setOpened(true)}>
                  TELL ME MORE
                </Button>
              </div>
            </Grid>
            <Grid item md={3}>
              <Sidebar sidebar={HowItWorks} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default LoanOrigination;
