import React from "react";
import "./App.scss";
// import { Switch, Route } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";

import Home from "./app/pages/Home";

// How Works
import WhatIsFaircentPro from "./app/pages/WhatIsFaircentPro";
import TechStack from "./app/pages/TechStack";
import ServicesProvided from "./app/pages/ServicesProvided";
import WhatFaircentProDoes from "./app/pages/WhatFaircentProDoes";
import LoanOrigination from "./app/pages/LoanOrigination";
import CreditAppraisal from "./app/pages/CreditAppraisal";
import DisbursementSigningAgreement from "./app/pages/DisbursementSigningAgreement";
import LenderEscrowAccount from "./app/pages/LenderEscrowAccount";
import Repayments from "./app/pages/Repayments";
import EscrowRechargeOtherFees from "./app/pages/EscrowRechargeOtherFees";
import CollectionRecovery from "./app/pages/CollectionRecovery";
import ReportsAnalytics from "./app/pages/ReportsAnalytics";

// How Get Started
import WhoCanBecomeLender from "./app/pages/WhoCanBecomeLender";
import GetInTouchGetStarted from "./app/pages/GetInTouchGetStarted";
import DocumentsRequiredForKyc from "./app/pages/DocumentsRequiredForKyc";
import TheProcess from "./app/pages/TheProcess";

// About Us
import TheTeam from "./app/pages/TheTeam";
import PrivacyPolicy from "./app/pages/PrivacyPolicy";
import TermsOfUse from "./app/pages/TermsOfUse";
import Pricing from "./app/pages/Pricing";
// import TellMeMore from "./app/elements/TellMeMore";
import NoMatch from "./app/pages/NoMatch";
import ContactSuccess from "./app/pages/ContactSuccess";
import { UserProvider } from "./app/context/UserContext";
import OurLendingPartners from "./app/pages/OurLendingPartners";
import TellMeMore from "./app/components/TellMeMore";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/what-is-faircentPro" element={<WhatIsFaircentPro />} />
          <Route path="/services-provided" element={<ServicesProvided />} />
          <Route path="/what-faircentpro-does-not-do" element={<WhatFaircentProDoes />} />
          <Route path="/loan-origination" element={<LoanOrigination />} />
          <Route path="/credit-appraisal" element={<CreditAppraisal />} />
          <Route path="/disbursement-&-signing-agreement" element={<DisbursementSigningAgreement />} />
          <Route path="/lender-escrow-account" element={<LenderEscrowAccount />} />
          <Route path="/repayments" element={<Repayments />} />
          <Route path="/escrow-recharge-&-other-fees" element={<EscrowRechargeOtherFees />} />
          <Route path="/collection-&-recovery" element={<CollectionRecovery />} />
          <Route path="/reports-&-analytics" element={<ReportsAnalytics />} />

          <Route path="/who-can-lend-on-faircentpro" element={<WhoCanBecomeLender />} />
          <Route path="/get-in-touch-to-get-started" element={<GetInTouchGetStarted />} />
          <Route path="/documents-required-for-kyc" element={<DocumentsRequiredForKyc />} />
          <Route path="/the-process" element={<TheProcess />} />

          <Route path="/the-team" element={<TheTeam />} />
          <Route path="/faircentpro-tech-stack" element={<TechStack />} />
          <Route path="/our-lending-partners" element={<OurLendingPartners />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />

          <Route path="/pricing" element={<Pricing />} />

          <Route path="/tell-me-more" element={<TellMeMore />} />
          <Route path="/contact-successfull" element={<ContactSuccess />} />
          <Route element={NoMatch} />
        </Routes>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;
